import React, { useState, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { updateQuery } from '../redux/querySlice';
import { feedApiUrl } from '../constants/urls';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function Feed() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const queryTextRedux = useSelector((state) => state.queryText.value)
  const activityRedux = useSelector((state) => state.activity)
  const historyID = useSelector((state) => state.historicalChatID.value)

  const sessionToken = sessionStorage.getItem('token')

  const select = (e) => document.querySelector(e);
  const selectAll = (e) => document.querySelectorAll(e);

  const [feedTopics, setFeedTopics] = React.useState([])

  const getFeed = async () => {
    try {
        const feedItems = await axios.get(
          feedApiUrl,
          {
            headers: {
            Authorization: 'Basic ' + sessionToken,
            "Content-Type": "application/json",
            },
          }
        )

        if (feedItems?.data.length > 0 ) {
          setFeedTopics(feedItems.data[0])
          // console.log('data: ', feedItems.data[0])
          // console.log('feed items from api: ', feedItems)
        }

    } catch (error) {
      console.error('Error fetching feed topics:', error);
    }
  }

  const getStorylines = (text, id) => {
    // dispatch(updateQuery(text))
    ScrollTrigger.killAll()
    ScrollTrigger.killAll(true)
    ScrollTrigger.getById('feed')?.kill(true);
    navigate('/feed/'+id+'/storylines');
  }

  useEffect(() => {
    getFeed();
  },[])

  useEffect(() => {
    // Change opacity of link on scroll

    const feed = selectAll(".feed-link")
    const scrollerHeight = document.getElementById('scroller').clientHeight
    const feedBoxHeight = document.getElementById('feedBox').clientHeight
    const startMid = parseInt((scrollerHeight - feedBoxHeight) / 2)

    // console.log("scroller height: ", scrollerHeight)
    // console.log("feedbox height: ", feedBoxHeight)
    // console.log("startMid: ", startMid)

    if (feedTopics && feedTopics.length > 1 && (window.location.pathname.split("/").pop() !== 'storylines')) {

      feed.forEach((item, i) => {
        gsap.to(item,  {
          keyframes: {
            scale: [1, 1, 1, 1, 1],
            opacity: [1, 1, 1, 1, 1]
          },
          transformOrigin: 'left top',
          repeat: 0,
          yoyo: true,
          ease: "none",
        });
      });

      // console.log("container height:", document.getElementById('feed')?.clientHeight)

      gsap.from("#scroller", { duration: 1, opacity: 1 });
    }
  },[feedTopics])

  useEffect(() => {
    ScrollTrigger.killAll()
    ScrollTrigger.killAll(true)
    ScrollTrigger.getById('feed')?.kill(true);
  },[activityRedux, queryTextRedux])

  return (
    <div id="feedBox">
    <div id='feed-container'>
      <ul id='scroller' className="infinite-scroll-list">
        {feedTopics && feedTopics.map((item) => (
          <li className='feed-link' key={item.id} onClick={()=> getStorylines(item.query, item.id)}>
          {/* // dispatch(updateQuery(item.query))}> */}
            <div className="thumbnail-container">
              <img src={item.thumbnail_url} />
            </div>
            {item.display_text || item.query}
          </li>
        ))}
      </ul>
    </div>
    </div>
  );
}
