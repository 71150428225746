
// dev gateway for Kong
let wsApiGateway = 'wss://w5wt991ve4.execute-api.us-west-1.amazonaws.com/development/';
// TODO REST APIs for Kong don't have dev endpoints yet. Replace these with the correct dev endpoints when they are available
let loginURL = 'https://api.shorttok.com/kong-api/login'
let feedApiUrl = 'https://api.shorttok.com/kong-api/video-story-feed'
let storylinesAPI = 'https://api.shorttok.com/kong-api/storylines-for-query'
let mediaAPI = 'https://api.shorttok.com/kong-api/media'

let localUrl = 'http://localhost:8080/';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  // these are all the same as the defaults for now to ensure no breakage
  console.log('production config detected')
  wsApiGateway = 'wss://ziutv5mpk1.execute-api.us-west-1.amazonaws.com/production/';
  loginURL = 'https://api.shorttok.com/kong-apit/login'
  feedApiUrl = 'https://api.shorttok.com/kong-api/video-story-feed'
  localUrl = 'http://localhost:8080/'
  storylinesAPI = 'https://api.shorttok.com/kong-api/storylines-for-query'
  mediaAPI = 'https://api.shorttok.com/kong-api/media'

}


export {
  wsApiGateway,
  localUrl,
  loginURL,
  feedApiUrl,
  storylinesAPI,
  mediaAPI
}
