import * as React from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import { Box, Button, Drawer, CssBaseline, IconButton, InputAdornment, Toolbar, TextField, Switch, Typography, Grid, createSvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import UserLogin from './components/UserLogin';
import History from './components/History';
import UserNav from './components/UserNav';
import Results from './components/Results';
import Feed from './components/Feed';

import ScrollTrigger from "gsap/ScrollTrigger";

import { useSelector, useDispatch } from 'react-redux';
import { updateQuery } from './redux/querySlice';

const drawerWidth = 300;

const CloseSidebarIcon = createSvgIcon(
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m20 24h-16c-2.2 0-4-1.8-4-4v-16c0-2.2 1.8-4 4-4h16c2.2 0 4 1.8 4 4v16c0 2.2-1.8 4-4 4zm-16-22c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2z"/><path d="m8 24c-.6 0-1-.4-1-1v-22c0-.6.4-1 1-1s1 .4 1 1v22c0 .6-.4 1-1 1z"/><path d="m14 13c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3-3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-3 3c-.2.2-.4.3-.7.3z"/><path d="m17 16c-.3 0-.5-.1-.7-.3l-3-3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l3 3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"/></svg>,
  'CloseSidebar',
);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    minHeight: 'calc(100vh)',
    overflowAnchor: 'none',
    // overflow: 'hidden',
    position: 'relative',
    // background: '#EEF7FF',
    padding: window.innerWidth > 600 ? "0 24px 24px" : theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: window.innerWidth > 600 ? `-${drawerWidth}px` : 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: window.innerWidth > 600 ? `-${drawerWidth}px` : 0,
    })
  }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const QueryPlaceholder = () => {
  const username = sessionStorage.getItem('username');
  return (
    <p className='placeholder'>
      <span className='placeholder-highlight'>
        Hi {username.charAt(0).toUpperCase() + username.slice(1)}.
      </span>
      What story would you like to make?
    </p>

  )
}

export default function Layout() {

  const queryTextRedux = useSelector((state) => state.queryText.value)
  const activityRedux = useSelector((state) => state.activity)
  const historyID = useSelector((state) => state.historicalChatID.value)
  const chatIDredux = useSelector((state) => state.activeChatID.value)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const thisPath = window.location.pathname
  const thisPathSegments = thisPath.split('/').filter(segment => segment)
  // console.log(thisPathSegments)

  const token = sessionStorage.getItem('token');
  const username = sessionStorage.getItem('username');
  const sessionMode = sessionStorage.getItem('mode');

  const [zeroState, setZeroState] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [mini, setMini] = React.useState(false);
  const [queryText, setQueryText] = React.useState('')
  const [firstQuery, setFirstQuery] = React.useState(false)
  const [resultsList, setResultsList] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [mode, setMode] = React.useState(sessionMode || "light");
  const [openDevPanel, setOpenDevPanel] = React.useState(false);
  const [openAltClipsPanel, setOpenAltClipsPanel] = React.useState(false);
  const [altClipsDrawerContent, setAltClipsDrawerContent] = React.useState([]);
  const [openDebugPanel, setOpenDebugPanel] = React.useState(false);
  const [debugDrawerContent, setDebugDrawerContent] = React.useState([]);
  const [lastStoryPath, setLastStoryPath] = React.useState('')

  const DefaultLLMPrompt = "";

  const [LLMPrompt, setLLMPrompt] = React.useState(DefaultLLMPrompt);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleDrawerMinimize = () => {
    setMini(!mini);
  }

  const handleDevPanelClose = () => {
    setOpenDevPanel(false);
  }

  const handleAltCLipsPanelClose = () => {
    setOpenAltClipsPanel(false);
  }

  const handleDebugPanelClose = () => {
    setOpenDebugPanel(false);
  }

  const messagesEndRef = React.useRef()

  const scrollToBottom = () => {
    let chatBottom = document.getElementById('chatboxBottom')
    if (chatBottom) {
      // console.log('chatbottom exists')
      window.setTimeout( () => {
        chatBottom.scrollIntoView({behavior: 'smooth', duration: 0});
      }, 0);
    }
  }

  const handleChatReset = () => {
    let activityReset = []
    dispatch(updateQuery(''))
  }

  const scrollToTop = () => {
    var evt = document.createEvent('MouseEvents');
    evt.initEvent('wheel', true, true);
    evt.deltaY = +120;
    evt.deltaY = -120
    document.getElementById('main-container').dispatchEvent(evt);
  }

  const handleQuery = () => {
    ScrollTrigger.killAll();
    if (queryTextRedux.length > 0 && (thisPathSegments[thisPathSegments.length - 1] == 'story')) {
      setFirstQuery(false)
      dispatch(updateQuery(queryText))
    } else if ((thisPathSegments[thisPathSegments.length - 1] === 'storylines')){
      setFirstQuery(true)
      dispatch(updateQuery(decodeURIComponent(document.getElementById('storylines-label').textContent) + ' ' + queryText))
    } else {
      setFirstQuery(true)
      getStorylines(queryText);
    }
    let queryTextfield = document.getElementById('ask-smith');
    queryTextfield.value="";
    queryTextfield.blur()
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          ...(mode === 'light'
          ? {
              background: { default: '#EEF7FF' },
            }
          : {
              // palette values for dark mode
              background: { default: '#191D25' },
            }),
        },
      }),
    [mode]
  );

  const askSmith = () => {
    if(thisPathSegments[thisPathSegments.length - 1] !== 'story') {
      navigate('/q/'+encodeURIComponent(queryTextRedux)+'/story')
    }
    if (LLMPrompt !== DefaultLLMPrompt) {
      if (queryTextRedux.length !== '' && activityRedux.length === 0 ) {
        setZeroState(false);
        setResultsList(resultsList.concat(<Results
            queryText={queryTextRedux}
            isHistory={activityRedux.length > 0}
            theme={mode}
            instruction_prompt={LLMPrompt}
            open_alt_clips_panel={setOpenAltClipsPanel}
            alt_clips_drawer={setAltClipsDrawerContent}
            open_debug_panel={setOpenDebugPanel}
            debug_drawer={setDebugDrawerContent}
          />));
      }
      else if (activityRedux.length > 0) {
        // console.log("hold up, we have history")
        setZeroState(false)
        setResultsList(<Results
            queryText={queryTextRedux}
            isHistory={activityRedux.length > 0}
            chatID={historyID}
            addToHistory={true}
            activityLog={activityRedux}
            theme={mode}
            instruction_prompt={LLMPrompt}
            open_alt_clips_panel={setOpenAltClipsPanel}
            alt_clips_drawer={setAltClipsDrawerContent}
            open_debug_panel={setOpenDebugPanel}
            debug_drawer={setDebugDrawerContent}
          />)
      } else {
        setZeroState(false)
        // console.log("oi oi, we are adding to history")
        setResultsList(resultsList.concat(<Results queryText={queryTextRedux} isHistory={false} theme={mode} instruction_prompt={LLMPrompt}/>))
      }
    } else {
      if (queryTextRedux.length !== '' && activityRedux.length === 0 ) {
        setZeroState(false);
        setResultsList(resultsList.concat(<Results
            queryText={queryTextRedux}
            isHistory={activityRedux.length > 0}
            theme={mode}
            open_alt_clips_panel={setOpenAltClipsPanel}
            alt_clips_drawer={setAltClipsDrawerContent}
            open_debug_panel={setOpenDebugPanel}
            debug_drawer={setDebugDrawerContent}
          />));
      }
      else if (activityRedux.length > 0) {
        // console.log("hold up, we have history")
        setZeroState(false)
        setResultsList(<Results
            queryText={queryTextRedux}
            isHistory={activityRedux.length > 0}
            chatID={historyID}
            addToHistory={true}
            activityLog={activityRedux}
            theme={mode}
            open_alt_clips_panel={setOpenAltClipsPanel}
            alt_clips_drawer={setAltClipsDrawerContent}
            open_debug_panel={setOpenDebugPanel}
            debug_drawer={setDebugDrawerContent}
          />)
      } else {
        setZeroState(false)
        // console.log("oi oi, we are adding to history")
        setResultsList(resultsList.concat(<Results
            queryText={queryTextRedux}
            isHistory={false}
            theme={mode}
            open_alt_clips_panel={setOpenAltClipsPanel}
            alt_clips_drawer={setAltClipsDrawerContent}
            open_debug_panel={setOpenDebugPanel}
            debug_drawer={setDebugDrawerContent}
          />))
      }
    }
  }

  const getStorylines = (text) => {
    // console.log("called from query text box")
    navigate('/q/'+encodeURIComponent(text)+'/storylines');
  }


  React.useEffect(() => {
    const smith = document.getElementById('ask-smith')
    if (smith) {
      smith.value = '';
      // scrollToBottom();
    }

    if ((queryText.length > 0 || queryTextRedux.length > 0) && activityRedux.length < 1) {
      // getStorylines(queryText)
      setZeroState(false)
      // console.log("resultsList: ", resultsList)
      // console.log("new query string: ", queryTextRedux)
      scrollToBottom()
      askSmith()
      // if (LLMPrompt !== DefaultLLMPrompt) {
      //   setResultsList(resultsList.concat(<Results queryText={queryTextRedux} isHistory={false} theme={mode} instruction_prompt={LLMPrompt} />));
      // } else {
      //   setResultsList(resultsList.concat(<Results queryText={queryTextRedux} isHistory={false} theme={mode} />));
      // }
    }

    // if (activityRedux.length > 0) {
    //   console.log("adding to activity with chatID: ", historyID)
    //   setResultsList(<Results queryText={queryTextRedux} isHistory={activityRedux.length > 0} addToHistory={true} chatID={historyID} activityLog={activityRedux} theme={mode}/>)
    // }

    if(queryTextRedux.length > 1 && activityRedux.length > 1 && historyID.length > 1) {
        // console.log("you clicked to continue")
        setResultsList(<Results
            queryText={queryTextRedux}
            isHistory={activityRedux.length > 0}
            chatID={historyID}
            addToHistory={true}
            activityLog={activityRedux}
            theme={mode}
            open_alt_clips_panel={setOpenAltClipsPanel}
            alt_clips_drawer={setAltClipsDrawerContent}
            open_debug_panel={setOpenDebugPanel}
            debug_drawer={setDebugDrawerContent}
          />)
    }

  }, [queryTextRedux])

  React.useEffect(() => {
    if (firstQuery) {
      // console.log('keep mousewheel at top')
      scrollToTop();
    } else {
      // console.log("scroll to bottom")
      scrollToBottom();
    }
  }, [queryTextRedux])

  React.useEffect(() => {
    setResultsList([])
    if(activityRedux.length > 0) {
      setZeroState(false);
      askSmith();
      // scrollToBottom();
    }
    // console.log("chat activity history in redux store: ", activityRedux.length)
  }, [activityRedux])

  React.useEffect(() => {
    // console.log("historical chat ID is now: ", historyID)
    setResultsList([])
  },[historyID])


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', background: mode === 'light' ? '#EEF7FF' : '#191D25' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          color='transparent'
          elevation={0}
          sx={{
            borderBottom: "1px solid #eeeeee",
            background: "none transparent",
            position: "fixed",
            background: mode === 'light' ? '#EEF7FF' : '#191D25'
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 1, color: "#AC1FFF", }} //...(open && { display: 'none' }) }}
            >
              { open ? <CloseRoundedIcon sx={{fontSize: 48}} /> : <MenuRoundedIcon sx={{fontSize: 48}} /> }
            </IconButton>
            <Typography variant="h5" noWrap component="div" sx={{ display: 'flex', paddingTop: '6px', fontWeight: 900,fontSize: 16, color: "#AC1FFF" }}>
              <span className="brandPill">
                {/* <img src={logo} width={24} height={24}/>  */}
                <a href="/" style={{textDecoration: 'none', color: '#AC1FFF'}}>ShortTok <span className='release'>Kong</span></a>
              </span>
            </Typography>
            <div className='newStory'>
              <Button
                size='medium'
                variant='contained'
                disabled = {token ? false : true}
                style={{justifyContent: "flex-start"}}
                // onClick={handleChatReset}
                href="/"
                startIcon={
                  !token ? <LockPersonOutlinedIcon /> : <AddCircleOutlineIcon />
                }
                sx={{
                  '&:hover': {
                    background: '#AC1FFF',
                    color: '#FFFFFF'
                  },
                  display: 'flex',
                  // flexGrow: 1,
                  borderRadius: 12,
                  fontSize: 13,
                  fontWeight: 700,
                  letterSpacing: '-0.5px',
                  boxShadow: 0,
                  marginBottom: 1,
                  background: '#AC1FFF',
                  color: '#FFFFFF',
                  textTransform: 'capitalize'
                }}
              >
                New Story
              </Button>
            </div>

            {token && token !== 'undefined' && (
              <>
                <UserNav openDevPanel={setOpenDevPanel}/>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: mode === 'light' ? "#ffffff" : '#000000',
              border: 0,
              padding: "0 24px 24px",
              margin: "60px 12px 12px",
              borderRadius: "24px",
              height: "calc(100% - 84px)",
              boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
              width: mini ? '64px' : drawerWidth
            }
          }}
          sx={{
            width: mini ? '64px' : drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: mini ? '64px' : drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant={window.innerWidth > 600 ? "persistent" : "temporary"}
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton
            sx={{marginRight: -2}}
            onClick={handleDrawerClose} //{handleDrawerMinimize} //{handleDrawerMinimize}
            >
              {theme.direction === 'ltr' ? <CloseSidebarIcon style={{fill: '#999', width: 18}} /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>

        {token && token !== 'undefined' && (
          <History mini={mini}/>
        )}
        {/*
        <Box sx={{display: "flex"}}>
          <SettingsOutlined /> Settings
        </Box> */}

        {!mini && (
          <Box sx={{display: "flex", fontSize: 14, marginTop: 2}}>
            Dark Mode:
            <Switch
            checked= {mode === "light" ? false : true}
            size="small"
            onChange={() =>
              {
              setMode(mode === "light" ? "dark" : "light");
              sessionStorage.setItem('mode', mode === "light" ? "dark" : "light")}
              } />
          </Box>
        )}

        {/* {token && token !== 'undefined' &&  (
            <AdminNav />
        )} */}
        </Drawer>
        <Main open={open} id="main-container">

          <DrawerHeader />

          { (token === 'undefined' || !token) && (
            <UserLogin />
            // <Outlet />
          )}

          {(thisPathSegments.length > 1) && !zeroState && (thisPathSegments[thisPathSegments.length - 1] === 'storylines') && (
            <Grid container>
              <Grid item sm={12} md={1} lg={3}>
              </Grid>
              <Grid item sm={12} md={10} lg={6} style={{position: 'relative'}}>
                <Outlet />
              </Grid>
              <Grid item sm={12} md={1} lg={3}></Grid>
            </Grid>
            // <Feed theme={mode} />
          )}

          {(thisPathSegments.length < 1) && (thisPathSegments[thisPathSegments.length - 1] === 'feed') && (
            <Outlet theme={mode} />
            // <Feed theme={mode} />
          )}

          { token && token !== 'undefined' && zeroState && window.location.pathname !== '/feed' && (
            <div>
            <Grid container>
              <Grid item sm={12} md={1} lg={3}>
              </Grid>
              <Grid item sm={12} md={10} lg={6} style={{position: 'relative'}}>
                <Outlet />
              </Grid>
              <Grid item sm={12} md={1} lg={3}></Grid>
            </Grid>
            </div>
          )}

          { token && token !== 'undefined' && (!zeroState || activityRedux.length !== 0) && (thisPathSegments[thisPathSegments.length - 1] !== 'storylines') && (
            <Grid container>
              <Grid item sm={12} md={1} lg={2} xl={3}></Grid>
              <Grid item sm={12} md={10} lg={8} xl={6} id="resultsBox" sx={{paddingRight: "36px"}}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {setResultsList([]); handleChatReset(); navigate(-1);}}
                  startIcon={<KeyboardBackspaceIcon />}
                  sx={{
                      color: '#AC1FFF',
                      borderColor: '#AC1FFF',
                      borderRadius: 50,
                      fontSize: 10,
                      marginBottom: 4,
                      '&:hover': {
                          background: '#AC1FFF',
                          color: '#FFFFFF',
                          borderColor: '#AC1FFF',
                      }
                  }}
                  >
                      Back
                </Button>
                {resultsList}
                <div ref={messagesEndRef}  id="chatboxBottom" />
              </Grid>
              <Grid item sm={12} md={1} lg={2} xl={3}></Grid>
            </Grid>
          )}

          { token && token !== 'undefined' && window.location.pathname !== '/feed' && (
            <Grid
            container
            sx={{
              position: 'fixed', bottom: "0px", left: 0, paddingTop: 0, zIndex: 99, paddingBottom: 4,
              background: mode === 'light' ? 'linear-gradient(0deg, rgba(244,255,255,0.95) 0%, rgba(244,255,255,0.7) 40%, rgba(244,255,0,0) 100%)' : 'linear-gradient(0deg, rgba(25,29,37,1) 0%, rgba(25,29,37,1) 50%, rgba(25,29,37,0) 100%)'
            }}
            >
              <Grid item xs={1} sm={3}></Grid>
              <Grid item xs={10} sm={6} className='fancyForm'>
                <TextField
                  id="ask-smith"
                  variant="outlined"
                  disabled={loading}
                  fullWidth
                  // placeholder= {<QueryPlaceholder />}
                  onChange={(e) => {
                    setQueryText(e.target.value)
                  }
                  }
                  onSubmit={handleQuery}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleQuery();
                    }
                  }}
                  InputProps={{
                    sx: {
                      background: mode === 'light' ? '#2d3330' : '#121212',
                      color: '#ffffff',
                      padding: "4px 4px",
                      borderRadius: 24,
                      input: {
                        '&::placeholder': {
                          opacity: 1,
                          fontFamily: 'Roboto Condensed',
                          fontSize: 18,
                          fontWeight: 400
                        }
                      }
                      // border: '4px solid #2d3330'
                    },
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                        onClick={handleQuery}
                        sx={{
                          '&:hover': {
                            background: '#ffffff',
                            color: '#AC1FFF'
                          },
                          background: '#AC1FFF',
                          color: '#ffffff'}}
                        >
                          <ArrowForwardIcon fontSize='large'/>
                        </IconButton>
                        {/* <IconButton
                        variant='outlined'
                        // disabled
                        size='sm'
                        onClick={handleChatReset}
                        sx={{
                          marginLeft: 1,
                          '&:hover': {
                            background: '#ffffff',
                            color: '#AC1FFF'
                          },
                          background: '#AC1FFF',
                          color: '#ffffff'}}
                        >
                          <AddCircleOutlineIcon fontSize='large' />
                        </IconButton> */}
                      </InputAdornment>
                    )
                  }}
                />
                <QueryPlaceholder/>
              </Grid>
              <Grid item xs={1} sm={3}></Grid>
            </Grid>
          )}
        </Main>
        {/* <Drawer
          open={openDevPanel}
          anchor="right"
          variant={window.innerWidth > 600 ? "persistent" : "temporary"}
          PaperProps={{
            sx: {
              backgroundColor: mode === 'light' ? "#ffffff" : '#000000',
              backgroundImage: 'none',
              border: 0,
              // padding: "24px 24px",
              margin: "60px 12px 12px",
              borderRadius: "24px",
              height: "calc(100% - 84px)",
              boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
              width: mini ? '64px' : drawerWidth
            }
          }}
          sx={{
            // width: window.innerWidth * 0.5,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: window.innerWidth < 600 ? 'calc(100% - 24px)' : (window.innerWidth * 0.33),
              boxSizing: 'border-box',
            },
          }}
        >
          <DrawerHeader>
            <IconButton
              onClick={handleDevPanelClose}
            >
              {<CloseRoundedIcon />}
            </IconButton>
          </DrawerHeader>
          <Typography variant='h5' sx={{margin: 2, fontFamily: 'Roboto Condensed'}}>Customize the LLM Prompt:</Typography>
          <TextField
            multiline={true}
            rows={18}
            value={LLMPrompt}
            onChange={(e) => setLLMPrompt(e.currentTarget.value)}
            sx={{
              margin: 2,
              marginTop: 0,
              marginBottom: 0,
            }}
            InputProps={{
              sx: {
                fontSize: 14,
                fontFamily: 'monospace',
                borderRadius: "4px",
                background: sessionStorage.getItem('mode') === 'light' ? '#efefef' : '#000000',
                '&.Mui-focused': {
                  // background: '#AC1FFF0F',
                  outline: 0
                }
              }
            }}
          />

        </Drawer>
        <Drawer
          open={openDebugPanel}
          anchor="right"
          PaperProps={{
            sx: {
              backgroundColor: sessionStorage.getItem('mode') === 'light' ? "#FFFFFF" : '#000000',
              border: 0,
              margin: "60px 12px 12px",
              borderRadius: "24px",
              height: "calc(100% - 84px)",
              boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
              // width: mini ? '64px' : drawerWidth,
            }
          }}
          sx={{
            width: window.innerWidth * 0.33,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: window.innerWidth < 600 ? 'calc(100% - 24px)' : (window.innerWidth * 0.33),
              boxSizing: 'border-box',
            },
          }}
        >
          <DrawerHeader sx={{position: 'fixed', background: '#fff', borderRadius: '24px 24px 0 0', width: 'inherit', margin: 0, borderBottom: '1px solid #ccc', zIndex: 999}}>
            <Typography variant='h5' sx={{margin: 2, fontFamily: 'Roboto Condensed', fontWeight: 600, width: '100%'}}>
              <ManageSearchIcon sx={{mr: 2, fontSize: 24, verticalAlign: 'middle'}} />Debug
            </Typography>
            <IconButton onClick={handleDebugPanelClose}>
              {<CloseIcon />}
            </IconButton>
          </DrawerHeader>
          <div style={{paddingTop: 70}} />
          {debugDrawerContent}
        </Drawer> */}
        <Drawer
          open={openAltClipsPanel}
          anchor="right"
          PaperProps={{
            sx: {
              backgroundColor: sessionStorage.getItem('mode') === 'light' ? "#FFFFFF" : '#000000',
              border: 0,
              margin: "60px 12px 12px",
              borderRadius: "24px",
              height: "calc(100% - 84px)",
              boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
              // width: mini ? '64px' : drawerWidth,
            }
          }}
          sx={{
            width: window.innerWidth * 0.33,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: window.innerWidth < 600 ? 'calc(100% - 24px)' : (window.innerWidth * 0.33),
              boxSizing: 'border-box',
            },
          }}
        >
          <DrawerHeader sx={{position: 'fixed', background: '#fff', borderRadius: '24px 24px 0 0', width: 'inherit', margin: 0, borderBottom: '1px solid #ccc', zIndex: 999}}>
            <Typography variant='h5' sx={{margin: 2, fontFamily: 'Roboto Condensed', fontWeight: 600, width: '100%'}}>
            <VideoLibraryOutlinedIcon sx={{mr: 2, fontSize: 24, verticalAlign: 'middle'}} />Alternate Clips
            </Typography>
            <IconButton onClick={handleAltCLipsPanelClose}>
              {<CloseIcon />}
            </IconButton>
          </DrawerHeader>
          <div style={{paddingTop: 36}}>
          {altClipsDrawerContent}
          </div>
        </Drawer>
     </Box>
    </ThemeProvider>
  );
}
