import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { updateOverrideVideoId } from '../redux/overrideVideoIdSlice';
import { updateQuery } from '../redux/querySlice';
import { feedApiUrl } from '../constants/urls';
import { useParams } from "react-router-dom";
import { Alert, Button, CircularProgress } from "@mui/material";
import { Controller } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { ArrowBack, ArrowForward } from '@mui/icons-material';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import logo from '../static/logo.png'

import { storylinesAPI, mediaAPI } from "../constants/urls";

export default function Storylines() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const thisPath = window.location.pathname

    let { queryID, queryText } = useParams();

    const sessionToken = sessionStorage.getItem('token')

    const [feedTopics, setFeedTopics] = useState([])
    const [thisItem, setThisItem] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingItem, setLoadingItem] = useState(false)

    const [loadingStorylines, setLoadingStorylines] = useState(false)
    const [storylines, setStorylines] = useState([])
    const [itemIDs, setItemIDs] = useState([])
    const [storylineImgURLs, setStorylineImgURLs] = useState([])
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const [activeItem, setActiveItem] = useState(0)
    const [noResults, setNoResults] = useState(false)

    const [errorMsg, setErrorMsg] = useState('')

    const getFeed = async () => {
        try {
            const feedItems = await axios.get(
            feedApiUrl,
            {
                headers: {
                "Authorization": 'Basic ' + sessionToken,
                "Content-Type": "application/json",
                },
            })

            if (feedItems?.data.length > 0 ) {
            setFeedTopics(feedItems.data[0])
            // console.log('feed items data: ', feedItems.data[0])
            // console.log('feed items from api: ', feedItems)
            }

        } catch (error) {
            console.error('Error fetching feed topics:', error);
        }
    }

    const APIconfig = {
        headers: {
            'Authorization': 'Basic ' + sessionToken,
            "Content-Type": "application/json",
        }
    }

    const getStorylines = async () => {
        setErrorMsg('')
        // console.log("thisItem: ", thisItem)

        try {
            const allStorylines = thisItem[0].storylines

            let tempIDholder = []
            let i = 0

            if (allStorylines) {
                setStorylines(allStorylines)
                // console.log('storylines data: ', allStorylines)
                setLoadingStorylines(false)

                if (allStorylines.length === 0) {
                    console.log("no results")
                    setNoResults(true)
                }
            } else {
                setLoadingStorylines(false)
                setNoResults(true)
            }
        } catch (error) {
            setErrorMsg(error.message)
            console.error('Error fetching storylines:', error);
        }
    }

    const getMedia = async (items) => {
        const payload = items
        // console.log("this itemID: ", items)
        try {
            const storylineImages = await axios.post(mediaAPI, payload, APIconfig)
            // console.log("media: ", storylineImages)
            if(storylineImages.data.length > 0) {
                setStorylineImgURLs(storylineImages.data)
            }
        } catch (error) {
            setErrorMsg(error.message)
            console.error('Error fetching storylines:', error);
        }
    }

    useEffect(() => {
        getFeed();
    },[])

    useEffect(() => {
        setLoadingItem(true)
        if (queryID) {
            feedTopics.filter((item) => {
                if ( item.id === queryID) {
                    setThisItem([item])
                    setLoadingItem(false)
                }
            })
        } else {
            setThisItem([{display_text: queryText, query: queryText}])
            setLoadingItem(false)
        }

    },[feedTopics, window.location.pathname])

    useEffect(() => {
        setLoadingStorylines(true)
        if(thisItem.length > 0) {
            // console.log("called once")
            // console.log("thisItem length: ", thisItem.length)
            // console.log("this item: ", thisItem)
            getStorylines();
        }
    },[thisItem])

  return (
    <div id="storylines">
        <Button
        variant="outlined"
        size="small"
        onClick={() => {navigate('/'); window.location.reload();}}
        startIcon={<KeyboardBackspaceIcon />}
        sx={{
            color: '#AC1FFF',
            borderColor: '#AC1FFF',
            borderRadius: 50,
            fontSize: 10,
            '&:hover': {
                background: '#AC1FFF',
                color: '#FFFFFF',
                borderColor: '#AC1FFF',
            }
        }}
        >
            Back to Home
        </Button>
        <h1 className="storylines" id="storylines-label">
            {/* {thisItem.length > 0 ? <img src={thisItem[0].thumbnail_url || null} /> : <img src={null} />} */}
            { loadingItem && (
                <CircularProgress size={18} thickness={8} sx={{marginLeft: '12px', verticalAlign: 'middle', color: '#AC1FFF'}}/>
            )}
            {thisItem.length > 0 ? thisItem[0].display_text : null}
        </h1>
        <div className="stories">
            { loadingStorylines && !errorMsg && (
                <div className="prompt">
                    <img src={logo} width={24} />
                    <span>Fetching Storylines</span>
                    <CircularProgress size={18} thickness={8} sx={{marginLeft: '12px', verticalAlign: 'middle', color: '#AC1FFF'}}/>
                </div>
            )}

            { errorMsg.length > 1 && storylines.length < 1 && (
                <>
                    <Alert variant="text" severity="error" sx={{borderRadius: 48}}>Oops. Something went wrong while fetching for storylines: <strong>{errorMsg}</strong>.</Alert>
                </>

            )}

            { !loadingStorylines && storylines.length > 0 && (
                <div className="prompt">
                    <img src={logo} width={24} />
                    <span>Storylines</span>
                </div>
            )}
            {storylineImgURLs && storylines.length > 0 && (
                <div className="storyline-carousel">
                    <Swiper
                        effect={'coverflow'}
                        autoplay={true}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={false}
                        slidesPerView={'auto'}
                        initialSlide={activeItem}
                        watchOverflow={true}
                        // cssMode={false}
                        coverflowEffect={{
                            slideShadows: true,
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2.5,
                        }}
                        pagination={{ el: '.swiper-pagination', clickable: true }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper_container"
                        onSlideChange={index => {
                            setActiveItem(index.snapIndex)
                        }}
                    >
                    {storylineImgURLs.map((item, index) =>
                        <SwiperSlide key={index}
                        onClick={()=> {
                            if ("override_video_story_id" in item) {
                                dispatch(updateOverrideVideoId(item.override_video_story_id))
                                dispatch(updateQuery(item.phrase))
                            } else {
                                dispatch(updateQuery("Story on " + item.phrase))
                            }
                        }}
                        >
                            <img src={item.mediaThumbnail} />
                      </SwiperSlide>
                    )}
                    <div className="slider-controler">
                        <div className="swiper-button-prev slider-arrow">
                            <ArrowBack
                            sx={{
                            fontSze: 12,
                            color: "#666666",
                            '&:hover': {
                                color: "#AC1FFF"
                            }
                            }} />
                        </div>
                        <div className="swiper-button-next slider-arrow">
                            <ArrowForward
                            sx={{
                            fontSze: 12,
                            color: "#666666",
                            '&:hover': {
                                color: "#AC1FFF"
                            }
                            }} />
                        </div>
                        {/* <div className="swiper-pagination"></div> */}
                    </div>
                    </Swiper>
                </div>
            )}

            {!loadingStorylines && storylines && (
                <div className="carousel-titles">
                    <Swiper
                    autoplay={true}
                    direction="vertical"
                    loop={false}
                    slidesPerView={'auto'}
                    initialSlide={2}
                    grabCursor={true}
                    >
                        {storylines.map((item, index) =>
                            <SwiperSlide key={index} onClick={()=> {
                                if ("override_video_story_id" in item) {
                                    dispatch(updateOverrideVideoId(item.override_video_story_id))
                                    dispatch(updateQuery(item.phrase))
                                } else {
                                    dispatch(updateQuery("Story on " + item.phrase))
                                }
                            }}>
                                <div className={activeItem == index ? "storyline-title-active" : "storyline-title"} key={item.id}>
                                    <ArrowCircleRightIcon style={{marginRight: 8, fontSize: 20,}}/>{item.phrase}<br/>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            )}

            { noResults && (
                <Alert variant="text" severity="error" sx={{borderRadius: 48}}>No storylines were found. Please try a different query.</Alert>
            )}

        </div>
    </div>
  );
}


