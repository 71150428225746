import * as React from 'react';
import { useEffect } from 'react';
import ReactPlayer from 'react-player';

import { Accordion, AccordionActions, AccordionSummary, Box, Button, Tooltip, Slider, AccordionDetails, CircularProgress, TextField } from '@mui/material';

import LoaderVideo from './LoaderVideo';
import AltVideoCard from './AltVideoCard';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import CameraswitchOutlinedIcon from '@mui/icons-material/CameraswitchOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import { useInView } from "react-intersection-observer";

import moment from 'moment';
import { LocationOnOutlined } from '@mui/icons-material';

export default function VideoCard(props) {

  let [videos, setVideos] = React.useState(props.compilation)

  const videoURLs = videos.map(video => {
    return `${video['media_url']}#t=${video?.media_start_time},${video?.media_end_time}`
  })

  const { ref, inView, entry } = useInView({
    /* Options */
    threshold: 0,
    rootMargin: "-20% 0% -20% 0%"
  });


  const NEXT_VIDEO_MARGIN = 0.0
  // videos.map((video, index) => {
  //   console.log(index, ' video times: ', video.media_start_time, video.media_end_time)
  // })

  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [currentVideo, setCurrentVideo] = React.useState(0)
  const [theme, setTheme] = React.useState('light')
  const [playing, setPlaying] = React.useState(true)
  const [playerClassName, setPlayerClassName] = React.useState('react-player no-fade')
  const [playedTime, setPlayedTime] = React.useState(0)
  const [wrapperClassName, setWrapperClassName] = React.useState('player-wrapper')
  const [clipInterval, setClipInterval] = React.useState([])
  const [narrative, setNarrative] = React.useState([])
  const [fullNarrative, setFullNarrative] = React.useState(true)

  const toggleFullNarrative = () => {
    setFullNarrative(!fullNarrative)
  }

  const nextVideo = () => {
      if (currentIndex+1 < videos.length) {
        // below is current state
        setCurrentVideo(videos[currentIndex+1])
        setCurrentIndex(currentIndex+1)
      } else {
        setPlaying(false)
        setCurrentIndex(0)
        setCurrentVideo(0)
      }
  };

  const swapVideoClip = (index, altIndex, clipInterval) => {
    console.log('in swapVideoClip: ', index, altIndex)
    console.log('inSwap interval: ', clipInterval[1])
    var tmp = [...videos[index].alternate_clips]  // copy of alternate clips array
    tmp[altIndex] = videos[index]
    var tmp_caption = videos[index].caption
    videos[index] = videos[index].alternate_clips[altIndex]
    videos[index].alternate_clips = tmp
    videos[index].caption = tmp_caption

    if (videos[index]['id'] == clipInterval[0]) {
      videos[index].media_start_time = clipInterval[1][0]
      videos[index].media_end_time = clipInterval[1][1]
      videos[index].video_end_time = clipInterval[1][1] * 1000
      videos[index].video_start_time = clipInterval[1][0] * 1000
      videos[index].clip_duration = (clipInterval[1][1] - clipInterval[1][0])
    }

    console.log("swap video: ", videos[index])
    setVideos(videos)
    props.open_alt_clips_panel(false)
    setCurrentVideo(videos[index])
    // let getCompilationDuration = 0
    // if (videos.length > 0) {
    //   let narr = []
    //   videos.forEach(video => {
    //     if (video.video_end_time) {
    //       // console.log("duration: ", video.media_end_time - video.media_start_time)
    //       getCompilationDuration += ((video.video_end_time - video.video_start_time)/1000)
    //       narr.push(video.caption)
    //     }
    //   })
    //   setNarrative(narr);
    // }
  }

  const editCurrentClip = (index,  clipInterval) => {
    console.log('currentIndex: ', index)
    console.log('new interval: ', clipInterval[1])
    if (videos[index].id == clipInterval[0]) {
      videos[index].media_start_time = clipInterval[1][0]
      videos[index].media_end_time = clipInterval[1][1]
      videos[index].video_end_time = clipInterval[1][1] * 1000
      videos[index].video_start_time = clipInterval[1][0] * 1000
      videos[index].clip_duration = (clipInterval[1][1] - clipInterval[1][0])
    }
    setVideos(videos)
    props.open_alt_clips_panel(false)
    setCurrentVideo(videos[index])
    // let getCompilationDuration = 0
    // if (videos.length > 0) {
    //   let narr = []
    //   videos.forEach(video => {
    //     if (video.video_end_time) {
    //       // console.log("duration: ", video.media_end_time - video.media_start_time)
    //       getCompilationDuration += ((video.video_end_time - video.video_start_time)/1000)
    //       narr.push(video.caption)
    //     }
    //   })
    //   setNarrative(narr);
    // }
    // console.log('chat_id: ', currentThreadID)
    // console.log('user_id: ', sessionStorage.getItem('username'))
    // console.log('clip_id:', videos[index].clip_id)
    // console.log('new interval: ', clipInterval[1])
    // call rest endpoint to update the clip
    // const response = axios.post (
    //   userEventsURL,
    //   {
    //     "action" : "clip_interval_change",
    //     "chatId" : currentThreadID,
    //     "userId" : sessionStorage.getItem('username'),
    //     "clipId" : videos[index].clip_id,
    //     "newStartTime" : parseFloat(clipInterval[1][0]) * 1000,
    //     "newEndTime" : parseFloat(clipInterval[1][1]) * 1000
    //   },
    //   {
    //     headers: {
    //       Authorization: 'Basic ' + sessionStorage.getItem('token'),
    //       "Content-Type": "application/json"
    //     },
    //   }
    // )
  }

  const handleInterval = (id, interval) => {
    console.log("this video id: ", id)
    console.log("this video's interval: ", interval)
    let intervalArr = []
    intervalArr.push(id)
    intervalArr.push(interval)
    console.log("all together now! ", intervalArr)
    setClipInterval(intervalArr)
  }

  const generateAltClipsDrawer = () => {
    // console.log('videos: ', videos)
    return (
      <Box p={4}>
        <div style={{
          border: '1px solid #AC1FFF',
          padding: 24,
          marginTop: 36,
          borderRadius: 12,
        }}>
          <p className='alt-clips-headertag'>Current Clip</p>
          <div>
            <p className='alt-clip' style={{fontFamily: 'Roboto Condensed', fontSize: 20, fontWeight: 600, color: "#000000de", marginTop: 18}}>{videos[currentIndex].headline}</p>
            <AltVideoCard altVideo={videos[currentIndex]} handleInterval={handleInterval}/>
            <Button
                  size='medium'
                  variant='contained'
                  style={{justifyContent: "flex-start"}}
                  onClick={() => editCurrentClip(currentIndex, clipInterval)}
                  startIcon={
                    <SwapHorizontalCircleOutlinedIcon />
                  }
                  sx={{
                    '&:hover': {
                      background: '#AC1FFF',
                      color: '#FFFFFF'
                    },
                    display: 'flex',
                    borderRadius: 12,
                    fontSize: 13,
                    fontWeight: 700,
                    letterSpacing: '-0.5px',
                    boxShadow: 0,
                    marginTop: 2,
                    marginBottom: 1,
                    background: '#AC1FFF',
                    color: '#FFFFFF',
                    textTransform: 'capitalize'
                  }}
                >
                  Save Edit
                </Button>
            {/*
            <p className='alt-clip-metadata'>
            <CalendarMonthIcon sx={{verticalAlign: 'middle', fontSize: 18}}/> {videos[currentIndex].first_pub_date}
            <TimerOutlinedIcon sx={{verticalAlign: 'middle', fontSize: 18, ml: 4}}/> {Math.floor(videos[currentIndex].clip_duration / 60)}:{Math.floor(videos[currentIndex].clip_duration % 60) < 10 ? '0' : ''}{Math.floor(videos[currentIndex].clip_duration % 60)}</p>
            <p className='alt-clip-body'>{videos[currentIndex].moment_summary}</p>
            <video className='alt-clip' controls style={{borderRadius: 12}}><source src={`${videos[currentIndex].video_s3_presigned_url}#t=${parseFloat(videos[currentIndex].video_start_time) / 1000},${parseFloat(videos[currentIndex].video_end_time) / 1000}`}></source></video> */}
          </div>
        </div>
        { videos[currentIndex].alternate_clips && videos[currentIndex].alternate_clips.length > 0 ? (
          <div style={{
            border: '1px solid #cccccc',
            padding: 12,
            marginTop: 36,
            borderRadius: 12,
          }}>
            <p className='alt-clips-headertag2'><b>Replacements for Clip {currentIndex+1} </b></p>
            {videos[currentIndex].alternate_clips.map((clip, index) => (
              <Accordion
              // defaultExpanded
              sx={{mb: 2, mt: 2}}
              elevation={0}
              >
                <AccordionSummary
                  style={{fontFamily: 'Roboto Condensed', fontSize: 20, fontWeight: 600}}
                  sx={{
                    margin: '12px 0 0',
                    '& .Mui-expanded': {
                      margin: '12px 0 0',
                    }
                  }}
                  expandIcon={<ExpandMoreOutlinedIcon />}
                  >
                {index+1}. {clip.headline}
                </AccordionSummary>
                <AccordionDetails>
                  <AltVideoCard altVideo={clip} handleInterval={handleInterval}/>
                </AccordionDetails>
                <AccordionActions>
                <Button
                  size='medium'
                  variant='contained'
                  style={{justifyContent: "flex-start"}}
                  onClick={() => swapVideoClip(currentIndex, index, clipInterval)}
                  startIcon={
                    <SwapHorizontalCircleOutlinedIcon />
                  }
                  sx={{
                    '&:hover': {
                      background: '#AC1FFF',
                      color: '#FFFFFF'
                    },
                    display: 'flex',
                    borderRadius: 12,
                    fontSize: 13,
                    fontWeight: 700,
                    letterSpacing: '-0.5px',
                    boxShadow: 0,
                    marginBottom: 1,
                    background: '#AC1FFF',
                    color: '#FFFFFF',
                    textTransform: 'capitalize'
                  }}
                >
                  Swap & Use This Clip
                </Button>
                </AccordionActions>
              </Accordion>
            ))}
          </div>
          ) : (<div><p>no data</p></div>)}
      </Box>
    )
  };

  const generateDebugDrawerContent = (debugMessages) => {
    // console.log('generateDebugDrawerContent: ', debugMessages)
    return debugMessages.map((message) => {
      if (message['py/object'] === 'st_storytelling_core_lib.types.chat.OpenSearchDebugContext') {
        return (
          <>
            <TextField
              multiline={true}
              rows={10}
              label="Context"
              value={message['context_str']}
              sx={{
                margin: 1,
              }}
              InputProps={{
                readOnly: true,
                sx: {
                  fontSize: 12,
                  fontFamily: 'monospace',
                  borderRadius: "4px",
                  background: sessionStorage.getItem('mode') === 'light' ? '#efefef' : '#000000',
                  '&.Mui-focused': {
                    outline: 0
                  }
                }
              }}
            />
            <TextField
              multiline={true}
              rows={10}
              label="Context Metadata"
              value={JSON.stringify(message['metadata'])}
              sx={{
                margin: 1,
              }}
              InputProps={{
                readOnly: true,
                sx: {
                  fontSize: 12,
                  fontFamily: 'monospace',
                  borderRadius: "4px",
                  background: sessionStorage.getItem('mode') === 'light' ? '#efefef' : '#000000',
                  '&.Mui-focused': {
                    outline: 0
                  }
                }
              }}
            />
          </>
        )
      } else if (message['py/object'] === 'st_storytelling_core_lib.types.chat.LLMDebugContext') {
        return (
          <TextField
            multiline={true}
            rows={10}
            label="LLM Output"
            value={JSON.stringify(message['llm_output'], null, 2)}
            sx={{
              margin: 1,
            }}
            InputProps={{
              readOnly: true,
              sx: {
                fontSize: 12,
                fontFamily: 'monospace',
                borderRadius: "4px",
                background: sessionStorage.getItem('mode') === 'light' ? '#efefef' : '#000000',
                '&.Mui-focused': {
                  outline: 0
                },
                '.MuiInputLabel-root': {
                  background: sessionStorage.getItem('mode') === 'light' ? '#efefef' : '#000000',
                }
              }
            }}
          />
        )
      }
    })
  };

  let getCompilationDuration = 0
  if (videos.length > 0) {
    videos.forEach(video => {
      if (video.video_end_time) {
        // console.log("duration: ", video.media_end_time - video.media_start_time)
        getCompilationDuration += ((video.video_end_time - video.video_start_time)/1000)
      }
    })
    props.alt_clips_drawer(generateAltClipsDrawer())
  }

  let timeSoFar = 0
  const [currentTime, setCurrentTime] = React.useState(0)

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
      setPlaying(!playing);
    };

  const altClipsClickHandler = () => {
    // stop video playing if it is
    setPlaying(false)
    props.open_alt_clips_panel(true)
  }

  const debugClickHandler = () => {
    // stop video playing if it is
    setPlaying(false)
    if (props.debug_messages && props.debug_messages.length > 0) {
      props.debug_drawer(generateDebugDrawerContent(props.debug_messages))
    }
    props.open_debug_panel(true)
  }

  const [captions, setCaptions] = React.useState(false)

  const captionHandler = () => {
    setCaptions(!captions)
  }

  const [mute, setMute] = React.useState(false)
  const muteHandler = () => {
    setMute(!mute);
  }

  const handlePager = (index) => {
    setCurrentIndex(index)
    setCurrentVideo(videos[index])
    setPlayerClassName('react-player fade-in')
  }

  // the below regex extracts the soundbite language and speaker name and title
  const soundbite_regex = /\s*SOUNDBITE\s+\(([^\)]+)\):*\s+([^\:\|]+)[\:\|]+/;
  const soundbite_text = (text) => {
    if (!text) {
      return 'true'
    }
    const match = text.match(soundbite_regex);
    // console.log('match: ', match)
    if (match) {
      if (match[1] !== 'English') {
        return 'true'
      } else {
        return match
      }
    }
    return 'true'
  }

  useEffect(() => {
      setCurrentVideo(videos[currentIndex])
    },[currentIndex]);

  useEffect(() => {
    setTheme(sessionStorage.getItem('mode'))
  },[sessionStorage.getItem('mode'), theme]);

  useEffect(() => {
    if (videos.length > 0) {
      let foo = []
      videos.forEach(video => {
        foo.push(video.narrative_text)
      })
      // console.log('videos: ', videos)
      setNarrative(foo);
  }
  },[getCompilationDuration]);

  return (
    <div>
      <code className='progress'>
        {props.message}
        { !(props.message === "Fetching complete" || props.message === "An error occurred") && !props.isHistory && (
          <CircularProgress size={18} thickness={8} sx={{marginLeft: '12px', verticalAlign: 'middle', color: '#AC1FFF'}}/>
        )}
      </code>
      <p style={{marginTop: 0, fontSize: 16, padding: '0 24px 0 0'}}>
        { props.agentText?.length > 0 ? props.agentText : null }
      </p>
      { videos.length > 0 && (
        <div>
          <div className={wrapperClassName} ref={ref}>
            { currentIndex+1 < videos.length && (
                <>
                  <video key={currentIndex} src={`${videos[currentIndex]['video_s3_presigned_url']}#t=${parseFloat(videos[currentIndex].video_start_time) / 1000},${parseFloat(videos[currentIndex].video_end_time) / 1000}`} style={{display: 'none'}} preload='auto'/>
                  <video key={currentIndex+1} src={`${videos[currentIndex+1]['video_s3_presigned_url']}#t=${parseFloat(videos[currentIndex+1].video_start_time) / 1000},${parseFloat(videos[currentIndex+1].video_end_time) / 1000}`} style={{display: 'none'}} preload='auto'/>
                </>

            )}
            <ReactPlayer
              controls={false}
              muted={mute}
              playing={inView ? playing : false} // {playing}
              className={`${playerClassName}`}
              url={
                (currentIndex < videos.length) && (videos.length > 0) ?
                `${videos[currentIndex]['video_s3_presigned_url']}#t=${parseFloat(videos[currentIndex]?.video_start_time) / 1000},${parseFloat(videos[currentIndex]?.video_end_time) / 1000}`
                : null
              }
              width='100%'
              height='100%'
              progressInterval={100}
              onProgress={(prog) => {

                for (var i = 0; i<currentIndex; i++) {
                  timeSoFar += parseFloat(videos[i]['video_end_time'] - videos[i]['video_start_time'])/1000
                }

                setCurrentTime(timeSoFar + prog.playedSeconds - (videos[currentIndex]['video_start_time']/1000))

                setPlayedTime(prog.playedSeconds)

                let firstSecond = (prog.playedSeconds - (videos[currentIndex]['video_start_time']/1000))
                let lastSecond = ((videos[currentIndex]['video_end_time']/1000) - prog.playedSeconds)

                if ( firstSecond < 1.25 && firstSecond >= 0) {
                  setWrapperClassName('player-wrapper fade-in')
                } else if (lastSecond < 0.1) {
                  setWrapperClassName('player-wrapper fade-out')
                } else {
                  setWrapperClassName('player-wrapper')
                }

                if ((prog.playedSeconds >= (parseFloat((videos[currentIndex]['video_end_time']/1000) - NEXT_VIDEO_MARGIN)) && currentIndex < videos.length)) {
                  nextVideo()
                }

              }}
            />
            { playing && (Math.floor(currentTime / 60) === 0) && ((Math.round(currentTime) / 60) < 6) ? (
              <div className='video_titlecard'>
                <h3 style={{
                  height: '180px',
                  padding: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '3',
                  WebkitBoxOrient: 'vertical',
                }}
                >
                  {props.storyMetadata['title']}
                </h3>
              </div>
            ) : null}

            {/* { playing && */}
              {/* <div className='video_timestamp'>
                <CalendarMonthIcon style={{marginRight: 4, fontSize: 18, filter: 'drop-shadow( 0 0 5px rgba(0, 0, 0, 1))'}}/>
                {(videos[currentIndex]?.first_pub_date) || moment(videos[currentIndex]['video_timestamp']['epoch_millis']).format('MMMM DD, YYYY')}
                { videos[currentIndex]?.location != '' && (
                  <>
                  <LocationOnOutlined style={{marginRight: 4, marginLeft: 24, fontSize: 18,  filter: 'drop-shadow( 0 0 5px rgba(0, 0, 0, 1))'}}/>
                  {videos[currentIndex].location || videos[currentIndex]['location']}
                  </>
                )}

              </div> */}
            {/* } */}

              {/* { ((Math.round(currentTime) % 60) > 0) &&
                <div className={`caption_text has-captions-${videos[currentIndex]['timed_captions'].length > 0 && captions}`}>
                  {videos[currentIndex]?.caption || videos[currentIndex]?.narrative_text}
                </div>
              } */}

              { captions && window.innerWidth > 600 && videos[currentIndex]?.caption && soundbite_text(videos[currentIndex].description) === 'true' &&
                <div className='video_annotation'>
                  <div className='video_cc'>
                    {videos[currentIndex]?.caption}
                  </div>
                </div>
              }

              { captions && window.innerWidth > 600 && videos[currentIndex]?.caption && soundbite_text(videos[currentIndex].description).length === 3 &&
                <div className='video_annotation'>
                  <div className='video_cc'>
                    { soundbite_text(videos[currentIndex].description)[2]}
                  </div>
                </div>
              }

              { !captions && window.innerWidth > 600 && videos[currentIndex]['timed_captions'].map((caption, index) =>
                  ((parseFloat(caption['end_time'])/1000) >= playedTime) && ((parseFloat(caption['start_time'])/1000) <= playedTime) &&
                    <div className='video_annotation'>
                      <div className="video_cc">
                      {caption['text']}
                      </div>
                    </div>
              )}

          </div>

          <Box
            display="flex"
            py={1}
            className='video_playback'>
            { playing && inView ? (
                <PauseCircleOutlineIcon style={{fontSize: 36, marginRight:"12px", cursor: "pointer"}} onClick={playPauseHandler} />
              ):(
                <PlayCircleOutlineIcon style={{fontSize: 36, marginRight: "12px", cursor: "pointer"}} onClick={playPauseHandler}/>
              )
            }
            <div style={{flex: 1}}>
              <div style={{display: 'flex', marginTop: 4}}>
                { videos.length > 0 && videos.map((video, index) =>
                  <Tooltip
                  key={videos[index].id}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        background: "#ffffff",
                        color: "#666666",
                        boxShadow: "0 0 8px rgba(0,0,0,0.35)",
                        padding: 0,
                        borderRadius: 4,
                      }
                    }
                  }}
                  title={
                    <React.Fragment>
                      {/* {'Duration: ' + Math.round(videos[index]['duration']) + ' seconds'} <br /> */}
                      <img key={videos[index].id} src={videos[index].thumbnail_s3_presigned_url} style={{marginBottom: "-6px", borderRadius: "8px"}} width="100%" alt={videos[index].caption || videos[index].narrative_text}/>
                      <span className="clip-duration"><AvTimerIcon sx={{fontSize: 16, marginRight: 1}}/>Duration: {videos[index].clip_duration} seconds</span>
                    </React.Fragment>}
                  >
                    <div
                      key={index}
                      className={'videoPager videoPager-'+ index + (index === currentIndex ? ' isActive' : '') + ' ' + sessionStorage.getItem('mode')}
                      onClick={() => handlePager(index)}
                      style={{
                        flex: '1 1 0px',
                        height: '8px'
                      }}
                      />
                  </Tooltip>
                )}
              </div>
              <Slider
              sx={{
                padding: 0,
                "&.MuiSlider-root": {
                  padding: 0,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: "#e70088",
                  width: 0,
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#cccccc",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#e70088",
                  border: 0,
                },
                "& .MuiSlider-thumb": {
                  height: 2,
                  width: 2
                }
              }}
              disabled
              value={(currentTime/getCompilationDuration) * 100
              } />
            </div>

            <Box px={2} sx={{fontSize: 10, fontFamily: 'monospace'}}>
              {
                Math.floor(currentTime / 60) > 9
                ? Math.floor(currentTime / 60)
                : '0' + Math.floor(currentTime / 60)
              }:{
              Math.round(currentTime) % 60 > 9
              ? Math.round(currentTime) % 60
              : '0' + Math.round(currentTime) % 60
              }/
              {
              Math.floor(getCompilationDuration / 60) > 9
              ? Math.floor(getCompilationDuration / 60)
              : '0'+Math.floor(getCompilationDuration / 60)
              }:{
              Math.round(getCompilationDuration) % 60 > 9
              ? Math.round(getCompilationDuration) % 60
              : '0'+Math.round(getCompilationDuration) % 60
              }
            </Box>
            { mute ? (
              <VolumeOffOutlinedIcon onClick={muteHandler} style={{cursor: "pointer", fill: '#AC1FFF'}}/>
            ):(
              <VolumeMuteOutlinedIcon onClick={muteHandler} style={{cursor: "pointer"}}/>
            )
            }
            { captions ? (
              <ClosedCaptionIcon onClick={captionHandler} style={{cursor: "pointer", marginLeft: 4, fill: '#AC1FFF'}}/>
            ) : (
              <ClosedCaptionOffIcon onClick={captionHandler} style={{cursor: "pointer", marginLeft: 4}}/>
            )}
            <CameraswitchOutlinedIcon style={{cursor: "pointer", marginLeft: 8}} onClick={altClipsClickHandler}/>
          </Box>

          {props.storyMetadata && (<div className='story_metadata'>


          { captions && window.innerWidth < 600 && videos[currentIndex]?.caption && soundbite_text(videos[currentIndex].description) === 'true' &&
            <div className='video_annotation'>
              <div className='video_cc'>
                {videos[currentIndex]?.caption}
              </div>
            </div>
          }

          { captions && window.innerWidth < 600 && videos[currentIndex]?.caption && soundbite_text(videos[currentIndex].description).length === 3 &&
            <div className='video_annotation'>
              <div className='video_cc'>
                { soundbite_text(videos[currentIndex].description)[2]}
              </div>
            </div>
          }

          { !captions && window.innerWidth < 600 && videos[currentIndex]['timed_captions'].map((caption, index) =>
              ((parseFloat(caption['end_time'])/1000) >= playedTime) && ((parseFloat(caption['start_time'])/1000) <= playedTime) &&
                <div className='video_annotation'>
                  <div className="video_cc">
                  {caption['text']}
                  </div>
                </div>
          )}

            <h6 onClick={toggleFullNarrative}>
                { props.storyMetadata['title'] ? props.storyMetadata['title'] : null }
                { props.storyMetadata['title']?.length > 0 && (
                  fullNarrative ?
                    <ExpandLessOutlinedIcon
                    style={{
                      marginLeft: 16,
                      alignItems: 'center',
                      borderRadius: 50,
                      border: '1px solid'
                      }}
                      /> :
                      <ExpandMoreOutlinedIcon
                      style={{
                        marginLeft: 16,
                        borderRadius: 50,
                        border: '1px solid',
                        alignItems: 'center'
                        }}
                      />

                )}
            </h6>
            {/* <Button
            variant='outlined'
            size="small"
            onClick={toggleFullNarrative}
            sx={{
              fontSize: 10,
              borderRadius: 24,
            }}
            startIcon={
              fullNarrative ? <ExpandCircleDownOutlinedIcon/> :<ExpandCircleDownOutlinedIcon/>
            }
            >
            {fullNarrative ? 'Show current narrative' : 'Show full narrative'}
            </Button> */}
            {/* <p>{ props.storyMetadata ? props.storyMetadata['news_story'] : null }</p> */}
            {/* <p>{props.storyMetadata ? props.storyMetadata['caption'] : null}</p> */}
            { fullNarrative ? (
                <ul>
                  {narrative.filter(val => val !== "")
                      .filter((val, idx, arr) => {
                        return arr.indexOf(val) === idx
                      }).map(caption => {
                        if (caption === narrative[currentIndex]) {
                          return (<li style={{color: "#666666", "font-weight": "700"}}>{caption}</li>)
                        } else {
                          return (<li>{caption}</li>)
                        }
                      })
                  }
                </ul>
              ) : (
                <div></div>
              )
            }
            {/* <p>{videos[currentIndex].caption}</p> */}
          </div>)}
        </div>
        )}

        { videos.length < 1 && !props.socketComplete && (props.message !== "Fetching complete" || props.agentText !== "Something went wrong. Please try again soon.") && (
          <LoaderVideo />
        )}
    </div>
  );
}
