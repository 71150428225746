import * as React from 'react';

import { 
    Box, 
    Button,
    CssBaseline, 
    List, 
    ListItemAvatar, 
    ListItemButton, 
    ListItemText, 
    Typography
} from '@mui/material';

import { useSocket } from '../constants/useSocket';
import axios from 'axios';

import { ModeCommentOutlined, PlayArrow } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';

import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { updateHistoryID } from '../redux/historySlice';
import { updateChatID } from '../redux/threadSlice';
import { updateActivity } from '../redux/activitySlice';
import { updateQuery } from '../redux/querySlice';
import { feedApiUrl } from '../constants/urls';

export default function History(props) {

    const socket = useSocket()
    const queryTextRedux = useSelector((state) => state.queryText.value)
    const chatHistory = useSelector((state) => state.historicalChatID.value)
    const activityRedux = useSelector((state) => state.activity)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activity, setActivity] = React.useState([])
    const [mini, setMini] = React.useState(props.mini)
    const [activityDetails, setActivityDetails] = React.useState([])
    const [showMoreHistory, setShowMoreHistory] = React.useState(false)
    const [showMoreTopics, setShowMoreTopics] = React.useState(false)
    const username = sessionStorage.getItem('username')
    const sessionToken = sessionStorage.getItem('token')

    const [feedTopics, setFeedTopics] = React.useState([])

    const getFeed = async () => {
        try {
            const feedItems = await axios.get(
            feedApiUrl,
                {
                    headers: { 
                    Authorization: 'Basic ' + sessionToken,
                    "Content-Type": "application/json",
                    },
                }        
            )

            if (feedItems?.data.length > 0 ) {
            setFeedTopics(feedItems.data[0])
            // console.log('feed items from api: ', feedItems)
            }
            
        } catch (error) {
            console.error('Error fetching feed topics:', error);
        }
    };

    const handleChatReset = () => {
        let activityReset = []
        dispatch(updateQuery(''))
        dispatch(updateChatID(''))
        dispatch(updateHistoryID(''))
        dispatch(updateActivity([activityReset]))
      }

    const getHistory = () => {
        // console.log("getHistory() called")


        let msg = JSON.stringify({
            "action": "get-chat-sessions", 
            "user_id": username, 
        })

        socket.send(msg)
    
        socket.onmessage = (data) => {
            // console.log("history message back: ", JSON.parse(data.data))
            let historyData = JSON.parse(data.data)
            let history = [].concat(historyData)
            .sort((a, b) => a.create_time.epoch_millis < b.create_time.epoch_millis ? 1 : -1)
            setActivity(history)
            // console.log("update history chrono: ", history)
        }
    }

    const getChat = (chatID) => {
        // console.log('chatHistoryID: ', chatHistory)
        // console.log('querytext: ', queryTextRedux)
        // console.log('activityRedux: ', activityRedux)
        handleChatReset();

        navigate('/q/'+encodeURIComponent(chatID)+'/story')
      
        let msg = JSON.stringify({
            "action": "get-chat",
            "user_id": username,
            "chat_id": chatID
        })
        
        dispatch(updateChatID(chatID))
        dispatch(updateHistoryID(chatID))
        let activityReset = []
        dispatch(updateActivity(activityReset))
        setActivityDetails(activityReset)
        
        socket.send(msg)
        socket.onmessage = (data) => {
            let activityData = JSON.parse(data.data)
            // console.log("chat from history: ", activityData)
            setActivityDetails(activityDetails => [...activityDetails, activityData])
            // console.log("activityDetails[]: ", activityDetails)
            dispatch(updateActivity(activityDetails))
        }
    }

    const getTrendingItem = (text) => {
        handleChatReset()
        dispatch(updateQuery(text))
        // console.log('chatHistoryID: ', chatHistory)
        // console.log('querytext: ', queryTextRedux)
        // console.log('activityRedux: ', activityRedux)
    }

    const getStorylines = (text, id) => {
        // dispatch(updateQuery(text))
        navigate('/feed/'+id+'/storylines');
    
      }

    React.useEffect(() => {
        getFeed()
        socket.onopen = () => {
            getHistory()
        }
    },[])

    React.useEffect(() => {
        // console.log("query text changed")
        if(queryTextRedux.length != '') {
            getHistory()
        }
    },[queryTextRedux])

    React.useEffect(() => {
        // console.log("drawer mini state: ", props.mini)
        setMini(props.mini)
    },[props.mini])

    React.useEffect(() => {
        // console.log("chat activity history loaded: ", activityDetails)
        // handleChatReset()
        dispatch(updateActivity(activityDetails))
    }, [activityDetails])

    return (
        <Box sx={{flexGrow: 1, overflow: "auto", padding: 0}}>
        <CssBaseline />
        { props.mini && (
            <>
                <Button 
                size='large'
                variant='contained'
                disabled = {sessionToken ? false : true}
                onClick={handleChatReset}
                sx={{
                  '&:hover': {
                    background: '#FFFFFF',
                    color: '#AC1FFF',
                    boxShadow: 0,
                  },
                  position: 'absolute',
                  left: 0,
                  // flexGrow: 1,
                  borderRadius: 12,
                  width: "48px",
                  padding: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  letterSpacing: '-0.5px',
                  boxShadow: 0,
                  marginBottom: 1,
                  background: '#FFFFFF',
                  color: '#AC1FFF',                  
                }}
              >
                { !sessionToken ? <LockPersonOutlinedIcon fontSize='large'/> : <AddCircleOutlineIcon  fontSize='large'/> }
              </Button>
                <EqualizerOutlinedIcon 
                fontSize='large'
                sx={{
                    marginBottom: 4,
                    position: 'absolute',
                    left: 14,
                    top: '128px',
                }}
                /> 
                <TrendingUpIcon  
                fontSize='large'
                sx={{
                    marginBottom: 4,
                    position: 'absolute',
                    left: 14,
                    top: '184px',
                }}
                /> 
            </>
        )}
        { !props.mini && (
            <>
            <div style={{display: 'flex', marginTop: 0}}>
                <EqualizerOutlinedIcon style={{fontSize: 28}}/> 
                <Typography
                sx={{
                    //paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: 500,
                    marginLeft: 1,
                    fontFamily: 'Roboto Condensed'
                }}>
                    Recent
                </Typography>
            </div>
            <List>
                { activity?.length > 0 && activity?.slice(0,5).map((item) =>
                    <ListItemButton
                    key={item['chat_id']}
                    onClick={() => getChat(item['chat_id'])}
                    sx={{
                        borderRadius: '24px', 
                        paddingLeft: 0,
                        verticalAlign: 'middle',
                        '&:hover': {
                            color: '#AC1FFF',
                            background: 'none transparent'
                        }
                    }}
                    >
                        <ListItemAvatar sx={{minWidth: "24px", marginRight: 1}}>
                            <ModeCommentOutlined sx={{fontSize: "18px", padding: 0, marginTop: 1}}/> 
                        </ListItemAvatar>
                        <ListItemText
                            primary={item['title']}
                            // secondary={ moment(item['create_time']['epoch_millis']).fromNow() }
                            primaryTypographyProps={{
                                style: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1',
                                    WebkitBoxOrient: 'vertical',
                                }
                            }}
                        />
                    </ListItemButton>
                )}
                { activity?.length > 0 && showMoreHistory && activity?.slice(5, 15).map((item) =>
                    <ListItemButton
                    key={item['chat_id']}
                    onClick={() => getChat(item['chat_id'])}
                    sx={{
                        borderRadius: '24px', 
                        paddingLeft: 0,
                        verticalAlign: 'middle',
                        '&:hover': {
                            color: '#AC1FFF',
                            background: 'none transparent'
                        }
                    }}
                    >
                        <ListItemAvatar sx={{minWidth: "24px", marginRight: 1}}>
                            <ModeCommentOutlined sx={{fontSize: "18px", padding: 0, marginTop: 1}}/> 
                        </ListItemAvatar>
                        <ListItemText
                            primary={item['title']}
                            // secondary={ moment(item['create_time']['epoch_millis']).fromNow() }
                            primaryTypographyProps={{
                                style: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1',
                                    WebkitBoxOrient: 'vertical',
                                }
                            }}
                        />
                    </ListItemButton>
                )}
            </List>
            {activity?.length > 5 && (
                <Button 
                    variant="text" 
                    // disabled={showMore}
                    sx={{
                        borderRadius: 24, 
                        fontSize: 10, 
                        textAlign: 'left', 
                        display: 'block', 
                        margin: 0, 
                        padding: 0,
                        color: '#888888',
                        '&:hover':{
                            color: '#AC1FFF',
                            background: 'none transparent'
                        }
                    }}
                    onClick={() => setShowMoreHistory(!showMoreHistory)}
                    >
                    {showMoreHistory ? 'Show Less' : 'Show All Recent Activity'}
                </Button>
            )}
            <div style={{display: 'flex', marginTop: 24}}>
                <TrendingUpIcon style={{fontSize: 28}}/> 
                <Typography
                sx={{
                    //paddingLeft: 2,
                    fontSize: 20,
                    fontWeight: 500,
                    marginLeft: 1,
                    fontFamily: 'Roboto Condensed'
                }}>
                    Trending
                </Typography>
            </div>
            <List>
                { feedTopics?.length > 0 && feedTopics?.slice(0,4).map((item) =>
                    <ListItemButton
                    key={feedTopics.indexOf(item)}
                    onClick={() => getStorylines(item.query, item.id)}
                    sx={{
                        borderRadius: '4px', 
                        padding: '4px 0 24px',
                        display: 'block',
                        '&:hover' : {
                            background: 'none transparent'
                        }
                    }}
                    >
                        <img src={item.thumbnail_url} style={{aspectRatio: '16 / 9', maxWidth: '100%', display: 'block', borderRadius: 12}} />
                        <PlayArrow
                        sx={{
                            position: 'absolute',
                            top: '60px',
                            left: '110px',
                            background: 'rgba(0,0,0,0.5)',
                            padding: 0,
                            borderRadius: 4,
                            color: '#ffffff'
                        }}
                        />
                        <ListItemText
                            primary={item.display_text || item.query}
                            // secondary={ moment(item['create_time']['epoch_millis']).fromNow() }
                            primaryTypographyProps={{
                                style: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1',
                                    WebkitBoxOrient: 'vertical',
                                }
                            }}
                        />
                    </ListItemButton>
                )}
                {feedTopics?.length > 0 && showMoreTopics && feedTopics.slice(4,10).map((item) => 
                    <ListItemButton
                    key={feedTopics.indexOf(item)}
                    onClick={() => getStorylines(item.query, item.id)}
                    sx={{
                        borderRadius: '4px', 
                        padding: '4px 0',
                        display: 'block',
                        '&:hover' : {
                            background: 'none transparent'
                        }
                    }}
                    >
                        <img src={item.thumbnail_url} style={{aspectRatio: '16 / 9', maxWidth: '100%', display: 'block', borderRadius: 12}} />
                        <PlayArrow
                        sx={{
                            position: 'absolute',
                            top: '60px',
                            left: '110px',
                            background: 'rgba(0,0,0,0.5)',
                            padding: 0,
                            borderRadius: 4,
                            color: '#ffffff'
                        }}
                        />
                        <ListItemText
                            primary={item.display_text || item.query}
                            // secondary={ moment(item['create_time']['epoch_millis']).fromNow() }
                            primaryTypographyProps={{
                                style: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1',
                                    WebkitBoxOrient: 'vertical',
                                }
                            }}
                        />
                    </ListItemButton>
                )}
            </List>
            {feedTopics?.length > 3 && (
                <Button 
                    variant="text" 
                    sx={{
                        borderRadius: 24, 
                        fontSize: 10, 
                        textAlign: 'left', 
                        display: 'block', 
                        margin: 0, 
                        padding: 0,
                        color: '#888888',
                        '&:hover':{
                            color: '#AC1FFF',
                            background: 'none transparent'
                        }
                    }}
                    onClick={() => setShowMoreTopics(!showMoreTopics)}
                    >
                    {showMoreTopics ? 'Show Less' : 'Show All Topics'}
                </Button>
            )}
        </>
        )}
        
        </Box>
    );
}