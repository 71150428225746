import { combineReducers } from '@reduxjs/toolkit';
import queryTextReducer from './querySlice';
import overrideVideoIdTextReducer from './overrideVideoIdSlice';
import historyReducer from './historySlice';
import activeChatReducer from './threadSlice';
import activityReducer from './activitySlice';


export default combineReducers({
    queryText: queryTextReducer,
    overrideVideoIdText: overrideVideoIdTextReducer,
    historicalChatID: historyReducer,
    activeChatID: activeChatReducer,
    activity: activityReducer
})


