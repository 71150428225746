import * as React from 'react';
import { useEffect, useRef } from 'react';

import { Button, Slider } from '@mui/material';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import { useInView } from "react-intersection-observer";

import moment from 'moment';

export default function AltVideoCard(props) {

  let [altVideo, setAltVideo] = React.useState(props.altVideo)
  const [sliderValue, setSliderValue] = React.useState([Math.floor(altVideo.media_start_time), Math.floor(altVideo.media_end_time)])
  const [startTime, setStartTime] = React.useState(altVideo.media_start_time)
  const [endTime, setEndTime] = React.useState(altVideo.media_end_time)
  const [altSource, setAltSource] = React.useState(altVideo.video_s3_presigned_url+'#t='+Math.floor(startTime)+','+Math.floor(endTime))

  const formatIntervalLabel = (value) => {
    return Math.floor(value / 60) + ':' + (Math.floor(value % 60) < 10 ? '0' : '') + Math.floor(value % 60)
  }

  const handleRangeSlider = (event, newValue) => {
    setSliderValue(newValue);
    let thisVideoID = altVideo.id || altVideo.video_story_clip_id
    props.handleInterval(thisVideoID, sliderValue)    
  };

  const handleResetInterval = () => {
    setSliderValue([Math.floor(altVideo.media_start_time), Math.floor(altVideo.media_end_time)])
    let thisVideoID = altVideo.id || altVideo.video_story_clip_id
    props.handleInterval(thisVideoID, [Math.floor(altVideo.media_start_time), Math.floor(altVideo.media_end_time)])
  }

  useEffect(() => {
    // console.log("updated slider value: ", sliderValue)
    setStartTime(sliderValue[0]);
    setEndTime(sliderValue[1]);
    setTimeout(() => {
        setAltSource(altVideo.video_s3_presigned_url+'#t='+sliderValue[0]+','+sliderValue[1])
    },750)
  },[sliderValue]);


  return (
    <>
        <p className='alt-clip'>
            <div className='alt-clip-metadata'>
                <CalendarMonthIcon sx={{verticalAlign: 'middle', fontSize: 18}}/> {altVideo.first_pub_date}
                <TimerOutlinedIcon sx={{verticalAlign: 'middle', fontSize: 18, ml: 4}}/> {Math.floor((endTime - startTime) / 60)}:{Math.floor((endTime - startTime) % 60) < 10 ? '0' : ''}{Math.floor((endTime - startTime) % 60)} <br/>
            </div>
            {/* {altVideo.moment_summary} */}
            { altVideo.description}
        </p>
        {/* <p className='alt-clip2'> Length: {Math.floor(clip.duration / 60)}:{Math.floor(clip.duration % 60) < 10 ? '0' : ''}{Math.floor(clip.duration % 60)}</p> */}
        <video key={altSource} style={{borderRadius: 12, marginTop: 18 }} className='alt-clip' controls><source src={altSource}></source></video>
        <div style={{display: 'flex', paddingTop: 12}}>
            <p className='alt-clip-interval-label'>Customize Interval:</p>
            <Button
            onClick={handleResetInterval} 
            variant="outlined" 
            size="small" 
            sx={{
                fontSize: 13, 
                color: '#666666',
                borderColor: '#666666',
                borderRadius: 12,
                fontWeight: 400,
                float: 'right', 
                padding: 0, 
                lineHeight: 0, 
                verticalAlign: 'middle',
                textTransform: 'capitalize',
                fontFamily: 'Roboto Condensed',
                '&:hover': {
                    borderColor: "#666666",
                    color: "#FFFFFF",
                    background: "#666666",
                }
            }}
            >
                Reset</Button>  
        </div>
        <Slider 
            fullWidth
            valueLabelDisplay="on"
            // key={index} 
            value={sliderValue}
            valueLabelFormat={(v) => formatIntervalLabel(v)}
            onChange={handleRangeSlider}
            // onChangeCommitted={handleRangeCommitted}
            min={0}
            max={altVideo.duration}
            sx={{
                padding: 0,
                marginBottom: 2,
                marginTop: 1,
                "& .MuiSlider-thumb": {
                    backgroundColor: "#ffffff",
                    border: "4px solid #666666",
                    height: 12,
                    width: 12
                },
                "& .MuiSlider-rail": {
                    backgroundColor: "#cccccc",
                },
                "& .MuiSlider-track": {
                    backgroundColor: "#666666",
                    border: 0,
                },
                "& .MuiSlider-mark": {
                    backgroundColor: "#AC1FFF",
                    height: 8,
                },
                "& .MuiSlider-valueLabel": {
                    backgroundColor: "#666666",
                    borderRadius: 12,
                    bottom: -54,
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    top: 'auto',
                    fontSize: 12,
                    "&::before": {
                        top: -7,
                        bottom: 'auto'
                    }
                },
            }}
        />   
    </>
  );
}
