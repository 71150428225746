import { wsApiGateway } from './urls';

import { createContext } from "react";

const socket = new WebSocket(wsApiGateway);

export const SocketContext = createContext(wsApiGateway);

interface ISocketProvider {
  children: Children;
}

export const SocketProvider = (props: ISocketProvider) => (
  <SocketContext.Provider value={socket}>{props.children}</SocketContext.Provider>
);

export default socket;
