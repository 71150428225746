import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { loginURL } from '../constants/urls';

import {Box, Grid, InputAdornment, IconButton, TextField} from '@mui/material';

import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useSearchParams } from 'react-router-dom';

export default function UserLogin() {

    const [user, setUser] = React.useState("");
    const [pwd, setPwd] = React.useState("");
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const handleLogin = async (e) => {
        try {
            const response = await axios.post (
            loginURL,
            {username: user, password: pwd, application: 'smith'},
                {
                    headers: { 
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Credentials": true },
                }
            )
            // console.log("login response: ", response);
            const userData = response.data;
            if (userData !== 'unauthorized') {
                sessionStorage.setItem('mode', 'light');
                sessionStorage.setItem('token', userData.token);
                sessionStorage.setItem('userId', userData.user_id);
                sessionStorage.setItem('username', userData.username);
                sessionStorage.setItem('storyline', userData.storyline_access);
                sessionStorage.setItem('devtools', userData.devtool_access);
                sessionStorage.setItem('videostory', userData.videostory_access);
                window.history.replaceState({}, document.title, window.location.pathname);
            } else {
                sessionStorage.clear()
                return navigate('/?loginerror=true');
            }
            navigate('/')
            window.location.reload();
        } catch (err) {
            sessionStorage.clear()
            console.log("Error: ", err);
            return navigate('/user/login');
        }
    } 
    
  return (
    <Grid container spacing={0}>
        <Grid sm={12} md={3} lg={4}></Grid>
        <Grid sm={12} md={6} lg={4}>
            <div class="loginCard">
                <h1 className="gradientText" style={{fontWeight: 900, fontSize: '36px', lineHeight: 1.2, textTransform: 'capitalize', margin: 0}}>Automated Video Storytelling from Real-World Videos</h1>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { my: 1 },
                        marginTop: '24px'
                    }}
                    noValidate
                    autoComplete="off"
                    >
                        <TextField 
                        fullWidth 
                        required
                        id="outlined-basic" 
                        label="Username" 
                        variant="standard" 
                        onChange={(e) => setUser(e.target.value)}
                        InputProps={{ 
                            sx: { 
                            //   background: '#ffffff', 
                            borderRadius: 4, 
                            //   border: '2px solid #eeeeee' 
                            }
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                            handleLogin();
                            }
                        }}
                        /> 
                        <TextField 
                        fullWidth 
                        required
                        id="outlined-basic" 
                        label="Password" 
                        type='password'
                        variant="standard" 
                        onChange={(e) => setPwd(e.target.value)}

                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                            handleLogin();
                            }
                        }}

                        InputProps={{ 
                            sx: { 
                            //   background: '#ffffff', 
                            borderRadius: 4, 
                            //   border: '2px solid #eeeeee' 
                            },
                            endAdornment: (
                                <InputAdornment position='start' style={{marginTop: -24, marginRight: 0}}>
                                    <IconButton
                                        onClick={handleLogin}
                                    >
                                    <ArrowCircleRightOutlinedIcon fontSize='large' />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        />
                        { searchParams.get('loginerror') && <h4 style={{color: 'red', textAlign: 'center'}}>Invalid username or password</h4> }
                </Box>
            </div>
        </Grid>
        <Grid sm={12} md={3} lg={4}></Grid>
    </Grid>
  );
}