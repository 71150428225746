import * as React from 'react';
import { LinearProgress } from '@mui/material';

import preloader from '../static/preloader.gif'

export default function LoaderVideo() {

  return (
    <>
      <div className='loader-video'>
      {/* <LinearProgress 
          sx={{
            // background: '#cccccc',
            background: 'linear-gradient(to right, rgba(131, 58, 180, 0.8) 0%, rgba(253, 29, 29, 0.8) 50%, rgba(252, 176, 69, 0.8) 100%)',
            '> span': { backgroundColor: 'rgba(0,0,0,0.5)' },
          }}
        /> */}
        <img src={preloader} />
      </div>
    </>
  );
}