import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Layout from "./Layout"

import UserLogin from "./components/UserLogin";
import Feed from "./components/Feed";
import Storylines from "./components/Storylines";

export default function App() {
    
  const router = createBrowserRouter([
    {
      // parent route component
      element: <Layout />,

      // child route components
      children: [
        {
          path: "/",
          element: <Feed />,
        },
        {
          path: "/home",
          element: <Feed />,          
        },
        {
          path: "/user/login",
          element: <UserLogin />
        },
        {
          path: "/user/profile",
        },
        {
          path: "/user/settings",
        },
        {
          path: "/user/logout",
          // action: logoutAction(),
        },
        {
          path: "/user/history/:historyID",
        },
        {
          path: "/:queryID"
        },
        {
          path: "/q/:queryText/story"
        },
        {
          path: "/feed",
          element: <Feed />
        },
        {
          path: "/feed/:queryID/storylines",
          element: <Storylines />
        },
        {
          path: "/q/:queryText/storylines",
          element: <Storylines />
        },
      ],
    },
  ])

  return (
      <RouterProvider router={router} />
  )
}