import * as React from 'react';

import { Box, Button } from '@mui/material';

import AccountCircle from '@mui/icons-material/AccountCircle';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { updateChatID } from '../redux/threadSlice';
import { wsApiGateway } from '../constants/urls';

import logo from '../static/logo.png'

import VideoCard from './VideoCard';

import { useSocket } from '../constants/useSocket';
import Carousel from './Carousel';

export default function Results(props) {

    let socket = useSocket();

    const dispatch = useDispatch()
    const overrideVideoStoryID = useSelector((state) => state.overrideVideoIdText.value)
    const currentThreadID = useSelector((state) => state.activeChatID.value)
    // console.log('props in results: ', props)
    const [socketResults, setSocketResults] = React.useState([])
    const [agentText, setAgentText] = React.useState('')
    const [chatId, setChatId] = React.useState('')
    const [startThread, setStartThread] = React.useState(false)
    const [relatedContent, setRelatedContent] = React.useState([])
    const [storyMetadata, setStoryMetadata] = React.useState([])
    const [socketComplete, setSocketComplete] = React.useState(false)
    const [progressMessage, setProgressMessage] = React.useState('')
    const [debugMessages, setDebugMessages] = React.useState([])

    const isVideoClip = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.KongVideoStoryClip')
    }

    const isAlternateClip = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.APAlternateClip')
    }

    const isChatMetaData = (message) => {
        return (message['py/object'] === 'st_smith_backend_common_lib.types.chat.ChatMetadata')
    }

    const isAgentText = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.AgentText')
    }

    const isAgentAnswer = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.APAgentAnswer')
    }

    const isServerError = (message) => {
        return (message["message"] === "Internal server error")
    }

    const isRelated = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.VideoStoryHeader')
    }

    const isStoryMetadata = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.VideoStoryMetadata')
    }

    const isCompletionToken = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.CompletionToken')
    }

    const isDebugMessage = (message) => {
        return (message['py/object'] === 'st_storytelling_core_lib.types.chat.OpenSearchDebugContext' ||
            message['py/object'] === 'st_storytelling_core_lib.types.chat.LLMDebugContext')
    }

    const newChatThread = (id) => {
        // console.log("ChatID: ", id)
        setChatId(id)
        setStartThread(true)
        dispatch(updateChatID(id))
    }

    const openSocket = () => {
        socket = new WebSocket(wsApiGateway)
    }

    const sendMsg = (e) => {
        if (socket.readyState === 1) {
            console.log("connection is established: ", socket.readyState)
            socket.send(e)
            handleMsg();
        } else {
            console.log("websocket connection state: ", socket.readyState)
            console.log("retrying after 1500ms")
            setTimeout(function () {
                sendMsg(e)
            }, 1500);
        }

    }

    const handleMsg = () => {
        socket.onmessage = (data) => {
            let thisMessage = JSON.parse(data.data)
            let thisMessageSeq

            if (thisMessage && isChatMetaData(thisMessage) && !startThread) {
                // console.log("this current path is: ", window.location.pathname)
                // console.log("this chatID is: ", thisMessage['chat_id'])
                window.history.replaceState(null, '', '/q/'+thisMessage.chat_id+'/story')
                newChatThread(thisMessage['chat_id'])
            }

            if (isChatMetaData(thisMessage)) {
                setProgressMessage("Fetching chat metadata")
            }

            if (isVideoClip(thisMessage)) {
                // console.log("isVideo!")
                setProgressMessage("Fetching videos")
                thisMessageSeq = convertPrimaryClip(thisMessage)
                setSocketResults(socketResults.push(thisMessageSeq))
                // setSocketResults(socketResults => [...socketResults, thisMessageSeq])
                // console.log("socketResults []: ", socketResults)
            } else if (isAlternateClip(thisMessage)) {
                setProgressMessage("Fetching alternate videos")
                thisMessageSeq = convertAlternateClip(thisMessage)
                if (socketResults.length > 0) {
                    var found = false;
                    for (var i = 0; i < socketResults.length; i++) {
                        if (socketResults[i].id === thisMessageSeq.id) {
                            socketResults[i].alternate_clips.push(thisMessageSeq)
                            break;
                        }
                    }
                    if (found) {
                        setSocketResults(socketResults)
                    }
                }
            } else if (isAgentText(thisMessage)) {
                // console.log("isAgentText!")
                // console.log("agent text: ", thisMessage['text'])
                setAgentText(thisMessage['text'])
            } else if (isServerError(thisMessage)) {
                // console.log("isAgentText!")
                // console.log("agent text: ", thisMessage['text'])
                setAgentText('Something went wrong. Please refresh and try again')
            } else if (isAgentAnswer(thisMessage)) {
                console.log("isAgentAnswer!")
                setAgentText(thisMessage['answer'])
                thisMessage['reference_clips'].forEach(refClip => {
                    setSocketResults(socketResults.push(convertBaseClip(refClip)))
                });
            } else if (isRelated(thisMessage)) {
                // console.log("isRelatedContent!")
                setRelatedContent(relatedContent => [...relatedContent, thisMessage])
                setProgressMessage("Fetching related stories")
                // console.log("relatedContent: ", relatedContent)
            } else if (isStoryMetadata(thisMessage)) {
                // console.log("isStoryMetadata!")
                setProgressMessage("Fetching story metadata")
                setStoryMetadata(thisMessage)
            } else if (isCompletionToken(thisMessage)) {
                // console.log("isCompletinoToken!")
                setProgressMessage("Fetching complete")
                setSocketComplete(true)
                if ('error_msg' in thisMessage && thisMessage['error_msg']) {
                    setProgressMessage("An error occurred")
                    setAgentText(thisMessage['error_msg'])
                }
            } else if (isDebugMessage(thisMessage)) {
                // console.log("Debug message: ", thisMessage)
                setDebugMessages(debugMessages => [...debugMessages, thisMessage])
            } else {
                setSocketResults(socketResults)
            }
        }
    }

    const askSmith = () => {
        // console.log("ID in redux store: ", currentThreadID)
        let msg

        if (props.instruction_prompt) {
            if (!startThread && currentThreadID.length < 8) {
                // console.log("first new chat")
                msg = {
                    "action": "get-chat-response",
                    "user_id": sessionStorage.getItem('username'),
                    "query": props.queryText,
                    "instruction_prompt": props.instruction_prompt
                }
            } else {
                // console.log("follow-up thread. this is id: ", currentThreadID)
                msg = {
                    "action": "get-chat-response",
                    "user_id": sessionStorage.getItem('username'),
                    "query": props.queryText,
                    "instruction_prompt": props.instruction_prompt,
                    "chat_id": currentThreadID
                }
            }
        } else {
            if (!startThread && currentThreadID.length < 8) {
                // console.log("first new chat")
                msg = {
                    "action": "get-chat-response",
                    "user_id": sessionStorage.getItem('username'),
                    "query": props.queryText
                }
            } else {
                // console.log("follow-up thread. this is id: ", currentThreadID)
                msg = {
                    "action": "get-chat-response",
                    "user_id": sessionStorage.getItem('username'),
                    "query": props.queryText,
                    "chat_id": currentThreadID
                }
            }
        }

        // console.log("overrideVideoStoryID: ", overrideVideoStoryID)
        if (overrideVideoStoryID) {
            msg.override_video_story_id = overrideVideoStoryID
        }

        msg = JSON.stringify(msg)
        if (socket.readyState > 1) {
            console.log("WebSocket connection is not OPEN, it is: ", socket.readyState)
            openSocket();
            sendMsg(msg);
        } else {
            sendMsg(msg)
            handleMsg(msg)
        }

        // setTimeout(socket.send(msg), 1000)
    }

    const formatQueryText = (query) => {
        return query.replace(/from \d{4}.*$/g, ' ').trim()

    }

    const convertBaseClip = (clip) => {
        var new_clip = {
            'first_pub_date': moment(clip['video_timestamp']['epoch_millis']).format('MMMM DD, YYYY'),
            'clip_id': clip['clip_id'],
            'media_start_time': parseFloat(clip['video_start_time']) / 1000,
            'media_end_time': parseFloat(clip['video_end_time']) / 1000,
            'duration': parseFloat(clip['video_duration']) / 1000,
            'media_url': clip['video_s3_presigned_url'],
            'media_thumbnail': clip['thumbnail_s3_presigned_url'],

            'video_start_time': clip['video_start_time'],
            'video_end_time': clip['video_end_time'],
            'video_duration': clip['video_duration'],
            'clip_duration': parseFloat(clip['video_end_time'] - clip['video_start_time']) / 1000,
            'video_s3_presigned_url': clip['video_s3_presigned_url'],
            'thumbnail_s3_presigned_url': clip['thumbnail_s3_presigned_url'],
            'caption': clip['video_caption'],
            'location': clip['location'],
            'timed_captions': clip['timed_captions'],
            'headline': clip['headline'],
            'description': clip['description'],
        }
        return new_clip
    }

    const convertPrimaryClip = (clip) => {
        var new_clip = convertBaseClip(clip)
        new_clip.id = clip['video_story_clip_id'];
        new_clip.narrative_text = clip['narrative_text']
        new_clip.alternate_clips = clip['alternate_clips']
        return new_clip
    }

    const convertAlternateClip = (clip) => {
        var new_clip = convertBaseClip(clip)
        new_clip.id = clip['video_story_clip_id'];
        return new_clip
    }

    // TODO: use this
    const convert_history_clips = (clips) => {
        // need to convert the clips from the format from the history call to the format the UI expects
        var newclips = []
        clips.forEach((clip) => {
            var newclip = convertPrimaryClip(clip)
            clip['alternate_clips'].forEach((alt_clip) => {
                var new_alt_clip = convertAlternateClip(alt_clip)
                newclip['alternate_clips'].push(new_alt_clip)
            })
            newclips.push(newclip)
        })
        return newclips
    }
    React.useEffect(() => {
        // console.log("props in results: ", props)
        if (!props.isHistory || (props.isHistory && props.addToHistory && props.activityLog.length > 0 && props.queryText.length > 0)) {
            askSmith()
        }
    }, [props])

    React.useEffect(() => {
        console.log("connection status: ", socket.readyState)
    }, [socket.readyState])

    return (
        <Box style={{
            marginBottom: 40
        }}>
            {props.isHistory === true ? (
                <div>
                    {props.activityLog.map((activity) => {

                        if (activity['py/object'] === 'st_storytelling_core_lib.types.chat.HumanText' && (activity['chat_id'] === props.chatID))
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 600, color: '#666666' }}>
                                    <AccountCircle sx={{ color: '#666666', fontSize: '36px', marginRight: 1 }} /> {activity.text}
                                </div>
                            )

                        if (activity['py/reduce'])
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 600, color: '#666666' }}>
                                    <AccountCircle sx={{ color: '#666666', fontSize: '36px', marginRight: 1 }} /> {activity['py/tuple'] ? activity['py/tuple'][0] : null }
                                </div>
                            )

                        if ((activity['py/object'] === 'st_storytelling_core_lib.types.chat.AgentText') && (activity['chat_id'] === props.chatID))
                            return (
                                <div style={{ marginBottom: "36px" }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 18, marginBottom: 12, fontSize: 16, fontWeight: 700, color: '#AC1FFF' }}>
                                        <img src={logo} width={36} />
                                    </div>
                                    <p>{activity['text']}</p>
                                </div>
                            )

                        if ((activity['py/object'] === 'st_storytelling_core_lib.types.chat.APAgentAnswer') && (activity['chat_id'] === props.chatID))
                            return (
                                <div style={{ marginBottom: "60px", display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 18, marginBottom: 12, fontSize: 16, fontWeight: 700, color: '#AC1FFF' }}>
                                        <img src={logo} width={36} />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <p>{activity['answer']}</p>
                                        <VideoCard
                                            compilation={activity['reference_clips']}
                                            isHistory={true}
                                            theme={props.theme}
                                            storyMetadata={''}
                                            socketComplete={socketComplete}
                                            open_alt_clips_panel={props.open_alt_clips_panel}
                                            alt_clips_drawer={props.alt_clips_drawer}
                                            open_debug_panel={props.open_debug_panel}
                                            debug_drawer={props.debug_drawer}
                                            debug_messages={debugMessages}
                                            message={progressMessage}
                                        />
                                    </div>
                                </div>
                            )

                        if ((activity['py/object'] === 'st_storytelling_core_lib.types.chat.APVideoStory') && (activity['chat_id'] === props.chatID))
                            return (
                                <div style={{ marginBottom: "60px", display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 18, marginBottom: 12, fontSize: 16, fontWeight: 700, color: '#AC1FFF' }}>
                                        <img src={logo} width={36} />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {activity['py/object'] === 'st_storytelling_core_lib.types.chat.AgentText' &&
                                            <p>{activity['text']}</p>
                                        }
                                        <VideoCard
                                            compilation={activity['clips']}
                                            isHistory={true}
                                            theme={props.theme}
                                            storyMetadata={activity['metadata']}
                                            socketComplete={socketComplete}
                                            open_alt_clips_panel={props.open_alt_clips_panel}
                                            alt_clips_drawer={props.alt_clips_drawer}
                                            open_debug_panel={props.open_debug_panel}
                                            debug_drawer={props.debug_drawer}
                                            debug_messages={debugMessages}
                                            message={progressMessage}
                                        />
                                        <Carousel items={activity['related']} socketComplete={true} />
                                    </div>
                                </div>
                            )
                    }
                    )}

                    {props.addToHistory === true && props.queryText.length > 1 && (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 600, color: '#666666' }}>
                                <AccountCircle sx={{ color: '#666666', fontSize: '36px', marginRight: 1 }} />{formatQueryText(props.queryText)}
                            </div>

                            <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 18 }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 4, marginBottom: 12, fontSize: 16, fontWeight: 700, color: '#AC1FFF' }}>
                                    <img src={logo} width={36} style={{ marginRight: '4px' }} />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <VideoCard
                                        compilation={socketResults}
                                        theme={props.theme}
                                        agentText={agentText}
                                        storyMetadata={storyMetadata}
                                        socketComplete={socketComplete}
                                        open_alt_clips_panel={props.open_alt_clips_panel}
                                        alt_clips_drawer={props.alt_clips_drawer}
                                        open_debug_panel={props.open_debug_panel}
                                        debug_drawer={props.debug_drawer}
                                        debug_messages={debugMessages}
                                        message={progressMessage}
                                    />
                                    <Carousel items={relatedContent} socketComplete={socketComplete} />
                                </div>
                            </div>
                        </div>
                    )
                    }

                </div>
            ) : (
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', fontWeight: 600, color: '#666666' }}>
                        <AccountCircle sx={{ color: '#666666', fontSize: '36px', marginRight: 1 }} />{formatQueryText(props.queryText)}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 18 }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 4, marginBottom: 12, fontSize: 16, fontWeight: 700, color: '#AC1FFF' }}>
                            <img src={logo} width={36} style={{ marginRight: '4px' }} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <VideoCard
                                compilation={socketResults}
                                theme={props.theme}
                                agentText={agentText}
                                storyMetadata={storyMetadata}
                                socketComplete={socketComplete}
                                open_alt_clips_panel={props.open_alt_clips_panel}
                                alt_clips_drawer={props.alt_clips_drawer}
                                open_debug_panel={props.open_debug_panel}
                                debug_drawer={props.debug_drawer}
                                debug_messages={debugMessages}
                                message={progressMessage}
                            />
                            <Carousel items={relatedContent} socketComplete={socketComplete} />
                        </div>
                    </div>
                </div>
            )}
        </Box>
    );
}
