import { createSlice } from '@reduxjs/toolkit'

export const threadSlice = createSlice({
  name: 'activeChatID',
  initialState: {
    value: '',
  },
  reducers: {
    updateChatID: (state, action) => {
      state.value = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { updateChatID } = threadSlice.actions

export default threadSlice.reducer