import { createSlice } from '@reduxjs/toolkit'

export const activitySlice = createSlice(
{
  name: 'activity',
  initialState: [],
  reducers: {
    updateActivity: (state, action) => {
      // console.log("updateActivity action: ", action.payload)
        // state.push(action.payload)
        Object.assign(state, action.payload)
    //   state.value = [action.payload]
    },
  }
})
// Action creators are generated for each case reducer function
export const { updateActivity } = activitySlice.actions

export default activitySlice.reducer