import * as React from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';

export default function UserNav(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const devToolAccess = sessionStorage.getItem('devtools');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('videostory');
    sessionStorage.removeItem('storyline');
    sessionStorage.removeItem('devtools');
    window.location.reload();
    setAnchorEl(null);
  };

  const handleDevPanel = () => {
    console.log('Dev Panel');
    props.openDevPanel(true);
    setAnchorEl(null);
  }

  return (
    <div>
        <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        sx={{
          size: "48px"
        }}
        >
        <AccountCircle 
          sx={{
            fontSize: "36px",
            color: sessionStorage.getItem('mode') === 'light' ? "#6d6e71" : "#999999"
          }}
        />
        </IconButton>
        <Menu
        elevation={0}
        sx={{ 
          mt: '45px', 
          background: 'none transparent',
          "& .MuiPaper-root": {
            backgroundColor: "transparent"
          }
        }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        { devToolAccess == 'true' && 
        <MenuItem key="settings" 
        onClick={handleDevPanel}
        sx={{
          background: sessionStorage.getItem('mode') === 'light' ? "#EEF7FF" : "none transparent",
          borderRadius: 60,
          border: '1px solid',
          fontSize: 14,
          marginBottom: 1,
          transition: 'all ease-in 0.2s',
          '&:hover': {
            background: '#AC1FFF',
            color: '#ffffff',
            borderColor: '#AC1FFF',
          }
        }}
        >
          <SettingsOutlinedIcon style={{fontSize: 18, marginRight: 12}}/>Dev Panel</MenuItem>
        }
        <MenuItem key="logout" 
        onClick={handleLogout}
        sx={{
          background: sessionStorage.getItem('mode') === 'light' ? "#EEF7FF" : "none transparent",
          borderRadius: 60,
          border: '1px solid',
          fontSize: 14,
          transition: 'all ease-in 0.2s',
          '&:hover': {
            background: '#AC1FFF',
            color: '#ffffff',
            borderColor: '#AC1FFF'
          }
        }}
        >
          <ExitToAppIcon style={{fontSize: 18, marginRight: 12}}/>Logout</MenuItem>
        </Menu>
    </div>
  );
}