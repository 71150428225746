import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import { useDispatch } from 'react-redux';
import { updateQuery } from '../redux/querySlice';

export default function Carousel(props) {

  const dispatch = useDispatch()

  return (
    <div className="container">
      { props.items.length > 0 && props.socketComplete &&
      <>
      <h6 className="heading"><FilterNoneIcon  sx={{mr: 1}}/>Related</h6>
      
      <Swiper
        effect={'coverflow'}
        autoplay={true}
        grabCursor={true}
        centeredSlides={true}
        loop={ props.items.length > 4 ? true : false}
        slidesPerView={'auto'} 
        initialSlide={props.items.length > 2 ? 1 : 0}
        watchOverflow={true}
        // cssMode={false}
        coverflowEffect={{
          slideShadows: true,
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        {props.items.map((item, index) => 
            <SwiperSlide
            key={index}
            onClick={()=> dispatch(updateQuery(item.header_text))}
            >
                <img src={item.thumbnail_s3_presigned_url} alt={item.header_text}/>
                <p className='carouselTitle'>{item.header_text}</p>
          </SwiperSlide>
        )}
        
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ArrowBack 
            sx={{
              fontSze: 12, 
              color: "#666666",
              '&:hover': {
                color: "#AC1FFF"
              }
              }} />
          </div>
          <div className="swiper-button-next slider-arrow">
            <ArrowForward 
            sx={{
              fontSze: 12, 
              color: "#666666",
              '&:hover': {
                color: "#AC1FFF"
              }
              }} />
          </div>
          {/* <div className="swiper-pagination"></div> */}
        </div>
      </Swiper>
      </>
      }
    </div>
    
  );
}