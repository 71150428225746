import { createSlice } from '@reduxjs/toolkit'

export const overrideVideoIdSlice = createSlice({
  name: 'overrideVideoIdText',
  initialState: {
    value: '',
  },
  reducers: {
    updateOverrideVideoId: (state, action) => {
      state.value = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { updateOverrideVideoId } = overrideVideoIdSlice.actions

export default overrideVideoIdSlice.reducer
